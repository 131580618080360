import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mitt from 'mitt';
const emitter = mitt();


const app = createApp(App);
app.config.globalProperties.$host = 'https://cloud.maestro3d.pro';
//app.config.globalProperties.$host = 'http://127.0.0.1:8000';
//app.config.globalProperties.$host='http://10.0.6.131:8000';
app.config.globalProperties.emitter = emitter;
app.use(store).use(router).mount('#app');