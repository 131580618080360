<template>

    <div class="window-entity window-entity_theme_common">

        <div class="i-font i-font_family_montserrat i-font_antialiased
        i-font_type_main-large i-font_align_center window-entity__head
        window-entity__head_theme_common">
            <p class="window-entity__header">
                Пользователи
            </p>

            <img src="@/assets/images/svg_images/Close.svg" class="window-entity__close-button link"
            @click="push('/')">
        </div>


        <div class="block-tree block-tree_size_common" @mouseover="makeActiveButton($event)"
            @mouseout="makeCommonButton($event)">

            <UserTreeView v-if="usersTree" :tree="usersTree" :left="'3.5em'"
            @userset="userset" @userdel="userdel">
            </UserTreeView>
        </div>

        <div class="window-entity__footer window-entity__footer_size_common"
            @mouseover="makeActiveButton($event)" @mouseout="makeCommonButton($event)">

            <div class="window-entity__icon element-click" @click="userreg" title="Добавить пользователя">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 33">
                    <path fill="#174766" fill-rule="evenodd" d="M.034 17.679c.543 8.854 8.03 15.718 17.67 15.283 2.423-.109 4.55-.897 6.463-1.83 5.816-2.838 9.154-8.976 8.809-15.86-.222-4.415-3.057-9.221-5.398-11.097C15.139-5.8-.836 3.452.034 17.679Z" clip-rule="evenodd"/>
                    <path fill="#174766" fill-rule="evenodd" d="M9.849 17.276c.446 3.282 3.537 6.086 7.376 5.588 3.363-.436 6.183-3.524 5.655-7.43-.448-3.315-3.537-6.138-7.419-5.61-3.302.45-6.143 3.549-5.612 7.452Z" clip-rule="evenodd"/>
                    <path fill="#fff" d="M14.248 18.506H7v-4.094h7.248V7h4.422v7.412H26v4.094h-7.33V26h-4.422v-7.494Z"/>
                </svg>
            </div>
        </div>

        <UserEditForm v-if="editformShow" :user="editformUser" @hide="editformShow = false, update()">
        </UserEditForm>

        <UserDeleteForm v-if="deleteformShow" :user="deleteformUser" @hide="deleteformShow = false, update()">
        </UserDeleteForm>

        <UserRegisterForm v-if="registerformShow" @hide="registerformShow = false, update()">
        </UserRegisterForm>

    </div>
</template>

<script>

import { getAllClusters, getAllUsers } from "../scripts/CatalogApi.js";
import { createUserTreeFromArray } from "../scripts/FunctionsForTreeView.js";
import { errorHandling } from "../scripts/FunctionsForServerResponse";
import UserEditForm from "../components/Users/UserEditForm";
import UserDeleteForm from "../components/Users/UserDeleteForm";
import UserRegisterForm from "../components/Users/UserRegisterForm";
import UserTreeView from "../components/Users/UserTreeView";
import router from "@/router";

export default {
    name: "UserList",
    components: {
        UserRegisterForm,
        UserEditForm,
        UserDeleteForm,
        UserTreeView
    },

    data() {

        return {
            clusters: null,
            usersTree: null,
            editformShow: null,
            editformUser: null,
            deleteformShow: null,
            deleteformUser: null,
            registerformShow: null,
        }
    },

    methods: {

        update() {
            getAllClusters(this.$host, this.$store.getters.USER.token).then((clustersResponse) => {
                getAllUsers(this.$host, this.$store.getters.USER.token).then((usersResponse) => {
                    this.usersTree = createUserTreeFromArray(usersResponse.data, clustersResponse.data);
                });
            }).catch((error) => {
                errorHandling(error, this);
            });
        },

        push(link) {
            router.push(link)
        },
        userset(userForSet) {
            this.editformShow = true;
            this.editformUser = userForSet;
        },
        userdel(userForDel) {
            this.deleteformShow = true;
            this.deleteformUser = userForDel;
        },
        userreg() {
            this.registerformShow = true;
        },
        makeActiveButton(event) {
            let targetElement = event.target;

            if (targetElement.tagName == 'path') {

                let paths = targetElement.parentNode.childNodes;
                for (let path of paths) {
                    if (path.getAttribute('fill') != '#fff') {
                        path.setAttribute('fill', '#316B90');
                    }
                }
            }
        },
        makeCommonButton(event) {
            let targetElement = event.target;

            if (targetElement.tagName == 'path') {

                let paths = targetElement.parentNode.childNodes;
                for (let path of paths) {
                    if (path.getAttribute('fill') != '#fff') {
                        path.setAttribute('fill', '#174766');
                    }
                }
            }
        }

    },

    mounted() {
        this.update();

    },

}
</script>

<style scoped>
</style>