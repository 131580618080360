<template>
    <div class="dialog-layer dialog-layer_color_gray">
        <div class="dialog dialog_color_common dialog_size_medium
        dialog_zindex_four">

            <div class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_align_center dialog__name">
                Добавление пользователя
            </div>

            <div class="dialog__inner">

                <form  @submit.prevent ="sendRequest">

                    
                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Кластер-владелец
                        </label>

                        <select class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        v-model="selCluster">
                            <option v-for="cluster in clusters" v-bind:value="cluster.id">
                                {{ cluster.name }}
                            </option>
                        </select>

                    </div>

                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Логин
                        </label>

                        <input class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common" 
                        :class=
                        "{'dialog-item--invalid': (v$.login.$invalid && v$.login.$dirty) || isUserErr }"
                        id="login"  placeholder="Email-адрес" v-model.trim="login">

                        <div v-if="v$.login.required.$invalid && v$.login.$dirty"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле не должно быть пустым
                        </div>

                        <div v-else-if="v$.login.email.$invalid && v$.login.$dirty"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле должно содержать e-mail адрес
                        </div>

                        <div v-else-if="isUserErr"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Такой пользователь уже существует!
                        </div>
                    </div>


                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Роль
                        </label>

                        <select class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        v-model="this.sel_role">
                            <option v-for="role in roles" v-bind:value="role.value">
                                {{ role.text }}
                            </option>
                        </select>
                    </div>




                    <div class="dialog__buttons-two buttons-two">
                        <button class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        type="submit" value="Submit">ОК</button>

                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        @click="this.$emit('hide')">
                            Отмена
                        </button>
                    </div>

                </form>


            </div>
        </div>

    </div>

</template>

<script>
    import useVuelidate from '@vuelidate/core'
    import {email, required,} from '@vuelidate/validators'
    import { getAllClusters, postUser } from '../../scripts/CatalogApi'
    import { errorHandling } from "../../scripts/FunctionsForServerResponse";

    export default {
        name: "UserRegisterForm",
        components: {

        },
        props: {

        },
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data(){
            return{

                clusters: null,
                selCluster: null,
                login: null,
                sel_role: 0,

                roles:[
                    {text: 'Наблюдатель', value: 0},
                    {text: 'Оператор', value: 1},
                    {text: 'Администратор', value: 2},
                ],


            }
        },
        mounted() {
            getAllClusters(this.$host, this.$store.getters.USER.token).then((response)=>{
                if (response.status===200) {
                    this.clusters=response.data;
                    this.selCluster=this.clusters[0].id;
                }
            }).catch((error)=>{
                errorHandling(error, this);
            });

        },

        validations () {
            return {

                login: {required, email, $autoDirty: true},

            }

        },

        methods: {

            sendRequest(){



                if (!this.v$.$invalid && this.selCluster) {
                    let user = {
                        owner: this.selCluster,
                        username: this.login,
                        is_operator: this.sel_role===1 ? true : false,
                        is_admin: this.sel_role===2 ? true : false

                    }

                    postUser(this.$host, this.$store.getters.USER.token, user).then((response)=>{
                        if (response.status===200) {
                                    this.emitter.emit("DisplayMessage", 
                                    {message: response.data, displaytime: 3000 });
                                    this.$emit('hide');

                        }
                    }).catch((error)=>{
                        this.emitter.emit("DisplayMessage", 
                        {message: error.response.status+": " + error.response.data, displaytime: 3000 });
                        this.$emit('hide');
                        errorHandling(error, this);  
                    });

                }
            },

        },



    }
</script>

<style scoped>

</style>