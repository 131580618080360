<template>
    <div class="panel-monitor">

        <div class="block-head block-head_size_common block-head_theme_common">
            <p class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_header-large i-font_align_center overflow-ellipsis
            block-head__header block-head__header_size_common">
                {{ dev.body.owner.name }} / {{ dev.body.name }} &nbsp; ({{ dev.body.definition }})
            </p>

            <div class="block-head__icon">
                <img v-if="isSignal" class="icon-rotate" src="../../assets/images/svg_images/CircleLoad.svg">
                <img v-if="!isSignal" src="../../assets/images/svg_images/CircleLoadError.svg">
            </div>  
            <!-- <SignalBar v-if="isOnline" :width="'8em'" :height="'1.7em'" :border-color="'var(--main-white-color)'"
                :background-color="'var(--main-black-color)'"
                :value="this.dev.body.state ? this.dev.body.state.TimeFromLastState : 31">
            </SignalBar> -->
        </div>

        <div class="field-information field-information__size_common">
            <p class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-small field-information__text">
                Последнее обновление: {{ timeLastState }}
            </p>
        </div>

        <div class="block-widgets block-widgets_size_common panel-monitor__widgets">
            <TempWidgetExtruder v-if="double_visible"
                :ttarget="dev.body.state ? dev.body.state.ExtLtempTarget : dev.body.states[0].ExtLtempTarget"
                :treal="dev.body.state ? dev.body.state.ExtLtempReal : dev.body.states[0].ExtLtempReal">
            </TempWidgetExtruder>

            <FilSensorWidget v-if="double_visible"
                :isEnable="dev.body.state ? dev.body.state.FilSensL : dev.body.states[0].FilSensL"
                :isError="dev.body.state ? dev.body.state.FilSensLerr : dev.body.states[0].FilSensLerr">
            </FilSensorWidget>

            <TempWidgetExtruder
                :ttarget="dev.body.state ? dev.body.state.ExtRtempTarget : dev.body.states[0].ExtRtempTarget"
                :treal="dev.body.state ? dev.body.state.ExtRtempReal : dev.body.states[0].ExtRtempReal">
            </TempWidgetExtruder>

            <FilSensorWidget :isEnable="dev.body.state ? dev.body.state.FilSensR : dev.body.states[0].FilSensR"
                :isError="dev.body.state ? dev.body.state.FilSensRerr : dev.body.states[0].FilSensRerr">
            </FilSensorWidget>

            <TempWidgetBed :ttarget="dev.body.state ? dev.body.state.BedtempTarget : dev.body.states[0].BedtempTarget"
                :treal="dev.body.state ? dev.body.state.BedtempReal : dev.body.states[0].BedtempReal">
            </TempWidgetBed>

            <CoolerWidget :speed="dev.body.state ? dev.body.state.CoolerSpeed : dev.body.states[0].CoolerSpeed">
            </CoolerWidget>

            <!-- <SixSquareBar :height="'75%'" :width="'1em'" :marginLeft="'1.5em'"
                :timeLastState="this.dev.body.state ? this.dev.body.state.TimeFromLastState: 31"
                :colorDisableSquare="'#ABABAB'" :colorActiveSquare="'#3D3D3D'">                   
                </SixSquareBar> -->
        </div>

        <div class="block-progress-big" v-if="isOnline && isFileInWork">
            <ProgressBarBig :width="'100%'" :height="'6em'" :value="this.dev.body.state ? this.dev.body.state.Percentcomplete :
            this.dev.body.states[0].Percentcomplete" :time-printing="printingTime" :time-end="endTime">
            </ProgressBarBig>
        </div>

        <p v-if="isOnline && isFileInWork" class="i-font i-font_family_montserrat i-font_antialiased 
        i-font_type_caption-small panel-monitor__last-file overflow-ellipsis">
            {{ linePrinting }}
        </p>

        <div v-if="this.$store.getters.USER.role"
            class="panel-monitor__buttons-circles buttons-circles">

            <div class="buttons-circles__button link" title="Печать" @mouseover="makeFocusedButton('run')"
                @mousedown="makeActiveButton('run')" @mouseout="makeCommonButton('run')"
                @mouseup="makeCommonButton('run')" @click="runPrinting">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 51 51">
                    <path id="svg_path_run" fill="#A9B4BB" fill-rule="evenodd"
                        d="M15.995 11.24c1.387.479 24.095 13.838 24.232 14.02L15.98 39.204l.015-27.963Zm34.422 16.493c1.091-10.329-4.545-20.292-13.012-24.656C20.375-5.7 1.79 5.375.128 22.691c-1.435 14.95 9.384 26.33 22.68 27.68 7.986.81 14.322-2.253 18.996-5.922 2.039-1.601 4.467-4.69 5.66-7.081 1.547-3.088 2.535-5.68 2.953-9.635Z"
                        clip-rule="evenodd" />
                    <path fill="#fff" fill-rule="evenodd"
                        d="m15.98 39.203 24.247-13.942c-.137-.181-22.845-13.542-24.232-14.021l-.015 27.963Z"
                        clip-rule="evenodd" />
                </svg>
            </div>

            <div class="buttons-circles__button link" title="Пауза" @mouseover="makeFocusedButton('pause')"
                @mousedown="makeActiveButton('pause')" @mouseout="makeCommonButton('pause')"
                @mouseup="makeCommonButton('pause')" @click="pausePrinting">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 52 51">
                    <path id="svg_path_pause" fill="#A9B4BB" fill-rule="evenodd"
                        d="m28.756 10.65 9.998-.005-.007 28.82-9.973.013-.018-28.829Zm-14.685.028 9.986-.025-.015 28.747-9.95.08-.021-28.802ZM51.59 28.17c1.1-7.397-2.317-15.406-5.875-19.328-1.984-2.187-3.859-3.981-7.088-5.663C28.634-2.025 17.68-.634 9.816 5.847-3.16 16.542-2.466 38.368 13.964 47.38c2.808 1.54 5.977 2.588 9.544 2.99 7.357.824 14.972-2.011 19.19-5.682 4.395-3.828 7.694-8.46 8.892-16.516Z"
                        clip-rule="evenodd" />
                    <path fill="#fff" fill-rule="evenodd"
                        d="m28.772 39.476 9.976-.01.006-28.82-9.998.003.016 28.827Zm-14.682.004 9.95-.08.017-28.747-9.986.025.018 28.802Z"
                        clip-rule="evenodd" />
                </svg>
            </div>

            <div class="buttons-circles__button link" title="Стоп" @mouseover="makeFocusedButton('stop')"
                @mousedown="makeActiveButton('stop')" @mouseout="makeCommonButton('stop')"
                @mouseup="makeCommonButton('stop')" @click="stopPrinting">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 52 51">
                    <path id="svg_path_stop" fill="#A9B4BB" fill-rule="evenodd"
                        d="m14.32 13.273 23.85-.005c.422 1.83.108 9.598.108 12.038 0 3.967.072 8-.012 11.965l-24-.107.055-23.891Zm37.111 14.501c1.427-13.57-8.48-26.095-22.57-27.626C15.167-1.34 2.636 8.553 1.132 22.712-.334 36.488 9.6 49.016 23.777 50.43c3.826.383 7.737-.156 11.055-1.395 9.427-3.513 15.552-11.287 16.598-21.26Z"
                        clip-rule="evenodd" />
                    <path fill="#fff" fill-rule="evenodd"
                        d="m14.265 37.164 23.999.107c.084-3.963.012-7.998.012-11.965 0-2.44.314-10.208-.109-12.038l-23.846.005-.056 23.891Z"
                        clip-rule="evenodd" />
                </svg>
            </div>

            <div class="buttons-circles__separator separator"></div>

            <div class="buttons-circles__button link" title="Вкл/выкл питание" @mouseover="makeFocusedButton('switch')"
                @mousedown="makeActiveButton('switch')" @mouseout="makeCommonButton('switch')"
                @mouseup="makeCommonButton('switch')" @click="switchPower">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 51 51">
                    <path id="svg_path_switch" fill="#A9B4BB" fill-rule="evenodd"
                        d="M.052 27.06c.83 13.552 12.29 24.058 27.046 23.392 3.709-.166 6.965-1.373 9.892-2.802C45.893 43.307 51 33.913 50.473 23.375c-.34-6.756-4.679-14.114-8.262-16.985C23.171-8.877-1.279 5.284.052 27.06Z"
                        clip-rule="evenodd" />
                    <path fill="#fff" fill-rule="evenodd"
                        d="M30.032 14.999c1.177.623 2.314 1.398 3.24 2.36.102.106.137.178.25.287.34.333.623.742.909 1.137.291.401.534.843.756 1.289.867 1.733 1.162 3.042 1.162 5.014 0 .479-.035.84-.035 1.293 0 .417-.116.81-.196 1.17-.306 1.362-.898 2.65-1.659 3.796l-1.926 2.167c-.709.674-1.589 1.192-2.467 1.624l-.96.405c-2.265.89-5.359.933-7.63.035-.175-.068-.331-.122-.491-.192-1.741-.768-2.64-1.51-3.904-2.77a8.345 8.345 0 0 1-.907-1.104 11.965 11.965 0 0 1-1.379-2.642c-.136-.356-.214-.656-.323-1.04-.28-.987-.308-2.097-.308-3.211 0-1.645.372-2.746.923-4.031.058-.134.103-.204.158-.343.198-.49.617-1.176.923-1.591.13-.177.278-.385.428-.543.099-.105.134-.17.232-.27.099-.098.16-.151.243-.258a4.13 4.13 0 0 1 .493-.512l.537-.469c.098-.084.143-.13.25-.216.57-.458 1.178-.836 1.807-1.209a1.21 1.21 0 0 1 .358-.145c0-.58.05-4.911-.018-5.438-.058-.425.067-.442-1.345.124-.617.246-1.151.518-1.735.814l-2.08 1.33-.933.79c-.101.083-.136.1-.233.198-.353.358-.779.669-1.085 1.07l-.802.923c-.135.166-.228.304-.353.472-1.078 1.454-2.074 3.454-2.551 5.166-.47 1.688-.64 3.139-.64 4.997 0 1.262.196 2.504.453 3.532.14.559.285.997.458 1.515.09.273.179.49.27.736l.614 1.358c.536 1.08 1.24 2.069 1.973 3.016.814 1.05 2.03 2.107 3.108 2.886.794.576 1.62 1.038 2.504 1.48 1.585.792 4.06 1.591 6.414 1.591h.489c1.725-.002 2.804-.03 4.594-.55 1.774-.517 3.357-1.17 4.837-2.198a25.285 25.285 0 0 0 1.156-.855l.544-.46c.362-.338.672-.638 1.022-.987.177-.177.316-.337.485-.52.736-.79 1.332-1.71 1.891-2.632.26-.427.467-.873.69-1.32 1.037-2.07 1.52-4.725 1.52-7.023 0-.697-.04-1.431-.111-2.079-.327-2.95-1.76-6.056-3.562-8.284-1.54-1.901-3.466-3.381-5.65-4.475a16.226 16.226 0 0 0-2.114-.863c-.114-.037-.258-.056-.338-.093v5.026c.004.282.044.428.044.722Z"
                        clip-rule="evenodd" />
                    <path fill="#fff" d="M27.947 6.42h-5.384v13.103h5.384V6.421Z" />
                </svg>
            </div>
        </div>

        <DeviceDetailedWindow :deviceId="dev.body.devid" :newCommand="newCommand">
        </DeviceDetailedWindow>

        <div class="description i-font i-font_family_montserrat
        i-font_antialiased i-font_type_main-medium overflow-ellipsis">

            <p class="description__part">
                <span class="i-font i-font_weight_normal">Модель:</span>
                {{ this.model }} &nbsp;
            </p>

            <p class="description__part">
                <span class="i-font i-font_weight_normal">S/N:</span>
                {{ dev.body.serialnumber }} &nbsp;
            </p>

            <p v-if="isOnline" class="description__part">
                <span class="i-font i-font_weight_normal">FW:</span>

                {{ this.dev.body.state ? this.dev.body.state.McomVersion :
                        this.dev.body.states[0].McomVersion
                }} &nbsp;
            </p>

            <p v-if="isOnline && !isOff" class="description__part">
                <span class="i-font i-font_weight_normal">P:</span>

                {{ this.dev.body.state ? this.dev.body.state.PlanerVersion :
                        this.dev.body.states[0].PlanerVersion
                }} &nbsp;
            </p>
        </div>

        <FileSelectForm v-if="isVisibleFileSelectForm" :cluster-id="this.dev.body.owner.id"
            :last-print-file="lastPrintFile" @file-selected="sendPrintCommand" @hide="isVisibleFileSelectForm = false">
        </FileSelectForm>
    </div>

</template>

<script>
import ProgressBarBig from "../Widgets/ProgressBarBig";
import TempWidgetExtruder from "../Widgets/TempWidgetExtruder";
import TempWidgetBed from "../Widgets/TempWidgetBed";
import CoolerWidget from "../Widgets/CoolerWidget";
import FilSensorWidget from "../Widgets/FilSensorWidget";
// import SignalBar from "../Widgets/SignalBar.vue";
import DeviceDetailedWindow from "./DeviceDetailedWindow.vue";
import FileSelectForm from "./FileSelectForm.vue";
// import SixSquareBar from "../SixSquareBar.vue";


export default {
    name: "DeviceDetailed",
    components: {
        ProgressBarBig,
        TempWidgetExtruder,
        TempWidgetBed,
        CoolerWidget,
        FilSensorWidget,
        // SignalBar,
        DeviceDetailedWindow,
        FileSelectForm
        // SixSquareBar,
    },
    props: {
        dev: {},
    },
    data() {
        return {
            color: null,
            colorstyle: null,
            newCommand: null,
            isVisibleFileSelectForm: false,
            buttonsCirclesStates: {
                run: false,
                pause: false,
                stop: false,
                switch: false
            }
        }
    },
    computed: {
        model() {
            return 'Maestro ' + this.dev.body.model.toUpperCase();
        },
        double_visible() {
            let devicesTwoExtruders = ["DUET", "GRAND2", "PICCOLO2"];
            let isDeviceTwoExtruders = devicesTwoExtruders.includes(this.dev.body.model.toUpperCase());
            return isDeviceTwoExtruders;
        },
        timeLastState() {
            let timeLastStateString = "неизвестно";
            if (this.dev.body.state) {
                timeLastStateString = Math.round(this.dev.body.state.TimeFromLastState);
                if (timeLastStateString > 60) {
                    timeLastStateString /= 60;
                    timeLastStateString = Math.round(timeLastStateString);
                    timeLastStateString += " мин. назад";
                }
                else {
                    timeLastStateString += " сек. назад";
                }
            }
            return timeLastStateString;
        },
        printingTime() {
            let printingTimeString = "--";

            if (this.dev.body.state && this.dev.body.state.PrintingTime) {
                //printingTimeString = this.timeToString(this.dev.body.state.PrintingTime);
                printingTimeString = this.dev.body.state.PrintingTime;
            }

            return printingTimeString;
        },
        endTime() {
            let endTimeString = "--";

            if (this.dev.body.state && this.dev.body.state.EndTime) {
                //endTimeString = this.timeToString(this.dev.body.state.EndTime);
                endTimeString = this.dev.body.state.EndTime;
            }

            return endTimeString;
        },

        linePrinting() {
            return "Печать | " + this.lastPrintFile;
        },

        lastPrintFile() {
            let lastFile = this.dev.body.state ? this.dev.body.state.LastPrintFile :
                this.dev.body.states[0].LastPrintFile;
            if (!lastFile) lastFile = "";
            return lastFile;
        },

        isSignal() {
            let signal = false;

            if (this.dev.body.state
            && this.dev.body.state.TimeFromLastState <31)
                signal = true;

            return signal;
        },

        isOnline() {
            let isOnlineState = true;

            let upperState = this.dev.body.state ? this.dev.body.state.UpperState :
                null;

            if (upperState == "OFFLINE") {
                isOnlineState = false;
            }

            return isOnlineState;
        },
        isOff() {
            let isOffState = true;

            if (this.deviceState != "OFF") {
                isOffState = false;
            }

            return isOffState;
        },
        isFileInWork() {
            let isFileInWorkState = true;

            switch (this.deviceState) {
                case "OFF":
                case "POWERED":
                case "IDLE":
                case "CANCELED":
                    isFileInWorkState = false;
                    break;
            }

            return isFileInWorkState;
        },
        deviceState() {
            let state = this.dev.body.state ? this.dev.body.state.State.toUpperCase() :
                this.dev.body.states[0].State.toUpperCase();
            return state;
        },

    },
    watch: {
        dev(newValue, oldValue) {
            if (newValue.body.state.State != oldValue.body.state.State) {
                this.computeButtonsCirclesStates();
            }
        }
    },
    methods: {
        computeButtonsCirclesStates() {
            let buttonsCirclesStatesMap = {
                run: false,
                pause: false,
                stop: false,
                switch: false
            }


            if (this.isOnline) {
                switch (this.deviceState) {
                    case "OFF":
                    case "ERROR":
                        buttonsCirclesStatesMap = {
                            run: false,
                            pause: false,
                            stop: false,
                            switch: true
                        }
                        break;
                    case "POWERED":
                        buttonsCirclesStatesMap = {
                            run: false,
                            pause: false,
                            stop: false,
                            switch: false
                        }
                        break;
                    case "IDLE":
                    case "FINISHED":
                    case "CANCELED":
                        buttonsCirclesStatesMap = {
                            run: true,
                            pause: false,
                            stop: false,
                            switch: true
                        }
                        break;
                    case "PRINTING":
                        buttonsCirclesStatesMap = {
                            run: false,
                            pause: true,
                            stop: true,
                            switch: false
                        }
                        break;
                    case "PAUSED":
                        buttonsCirclesStatesMap = {
                            run: true,
                            pause: false,
                            stop: true,
                            switch: false
                        }
                        break;
                    case "RESUMING":
                        buttonsCirclesStatesMap = {
                            run: false,
                            pause: false,
                            stop: true,
                            switch: false
                        }
                        break;
                }
            }

            if (this.$store.getters.USER.role) this.changeButtonColor(buttonsCirclesStatesMap);

            this.buttonsCirclesStates = buttonsCirclesStatesMap;
        },
        sendPrintCommand(file) {
            this.isVisibleFileSelectForm = false;

            if (file) {
                let fileId = file.fileId;
                let fileName = file.fileName;
                this.sendCommand("PRINT_NEW", fileId, fileName);
            }
            else {
                this.sendCommand("PRINT_LAST");
            }
        },
        sendCommand(commandName, value1 = null, value2 = null) {
            let command = { time: new Date(), name: commandName };

            if (value1) command.value1 = value1;

            if (value2) command.value2 = value2;

            this.newCommand = command;
        },
        runPrinting() {
            if (this.buttonsCirclesStates['run']) {
                if (this.deviceState == "IDLE"
                    || this.deviceState == "FINISHED"
                    || this.deviceState == "CANCELED")
                    this.isVisibleFileSelectForm = true;
                else if (this.deviceState == "PAUSED")
                    this.sendCommand("RESUME");
            }
        },
        pausePrinting() {
            if (this.buttonsCirclesStates['pause']) {
                this.sendCommand("PAUSE");
            }
        },
        stopPrinting() {
            if (this.buttonsCirclesStates['stop']) {
                this.sendCommand("STOP");
            }
        },
        switchPower() {
            if (this.buttonsCirclesStates['switch']) {
                if (this.isOff) this.sendCommand("POWER_ON");
                else this.sendCommand("POWER_OFF");
            }
        },
        changeButtonColor(buttonsCirclesStatesMap) {
            for (let key in buttonsCirclesStatesMap) {
                let element = document.getElementById('svg_path_' + key);

                if (buttonsCirclesStatesMap[key]) element.setAttribute('fill', '#174766');
                else element.setAttribute('fill', '#A9B4BB');
            }
        },
        makeFocusedButton(buttonName) {
            if (this.buttonsCirclesStates[buttonName]){
                let element = document.getElementById('svg_path_' + buttonName);
                element.setAttribute('fill', '#316B90');
            }           
        },
        makeActiveButton(buttonName) {
            if(this.buttonsCirclesStates[buttonName]){
                let element = document.getElementById('svg_path_' + buttonName);
                element.setAttribute('fill', '#052E49');
            }     
        },
        makeCommonButton(buttonName) {
            let element = document.getElementById('svg_path_' + buttonName);
            if (this.buttonsCirclesStates[buttonName]) element.setAttribute('fill', '#174766');
            else element.setAttribute('fill', '#A9B4BB');
        }
    },
    mounted(){
        this.computeButtonsCirclesStates();
    }
}
</script>

<style scoped>
.panel-monitor {
    background: var(--inner-background-color);
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.block-head {
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-head_size_common {
    height: 6.3%;
    width: 100%;
}

.block-head_theme_common {
    background-color: var(--main-black-color);
    color: var(--main-white-color);
}

.block-head__header {
    margin: 0;
    margin-right: 0.5em;
}

.block-head__header_size_common {
    max-width: 93%;
}

.block-head__icon {
    max-height: 100%;
    width: 3.5em;
}

@keyframes icon-moving {
    100% {transform: rotate(360deg);}
}

.icon-rotate {
    animation: icon-moving 3000ms infinite steps(8, end);
    transform-origin: center;
}

.field-information_size_common {
    width: 53%;
}

.field-information__text {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.block-progress-big {
    display: flex;
    justify-content: center;
    width: 53%;
}

.panel-monitor__widgets {
    gap: 0.4em;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
}

.block-widgets {
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-widgets_size_common {
    height: 20%;
}

.panel-monitor__last-file {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    max-width: 52%;
}

.buttons-circles {
    display: flex;
    justify-content: space-between;
    width: 17%;
    height: 5%;
}

.panel-monitor__buttons-circles {
    margin: 1.5em;
    margin-top: 1em;
}

.buttons-circles__button {
    width: 19%;
}

.separator {
    background-color: var(--main-black-color);
    width: 1px;
}

.buttons-circles__separator {
    margin-left: 2em;
    margin-right: 2em;
}

.description {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.description__part {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

@media (max-width: 480px) {
    .description {
        font-size: 1.5em;
    }

    .buttons-circles {
        height: 10%;
        width: 90%;
    }

    .block-progress-big {
        width: 90%;
    }

    .block-widgets {
        font-size: 0.7em;
    }
}
</style>