<template>
    <form @submit.prevent="sendRequest">

        <div class="dialog__field">
            <label class="i-font i-font_family_montserrat i-font_antialiased i-font_type_main-large">
                Кластер
            </label>

            <input class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_type_input
            dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
            v-bind:class="{ 'dialog__input-text_theme_invalid': v$.cluster.$invalid && v$.cluster.$dirty }"
            id="cluster" type="text"
            placeholder='"Мой дом"; "Учебный класс", и т.д.)' v-model="cluster">

            <div v-if="v$.cluster.required.$invalid && v$.cluster.$dirty"
            class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                Поле не должно быть пустым
            </div>
        </div>

        <div class="dialog__field">
            <label class="i-font i-font_family_montserrat i-font_antialiased i-font_type_main-large">
                Логин
            </label>

            <input class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_type_input
            dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
            v-bind:class="{ 'dialog__input-text_theme_invalid':
            (v$.login.$invalid && v$.login.$dirty) || isUserErr }"
            id="login" placeholder="Email-адрес" v-model.trim="login" @change="changeUserErr">

            <div v-if="v$.login.required.$invalid && v$.login.$dirty"
            class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                Поле не должно быть пустым
            </div>
            
            <div v-else-if="v$.login.email.$invalid && v$.login.$dirty"
            class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                Поле должно содержать e-mailадрес
            </div>
            
            <div v-else-if="isUserErr"
            class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                Такой пользователь уже существует!
            </div>
        </div>

        <div class="dialog__field">
            <label class="i-font i-font_family_montserrat i-font_antialiased i-font_type_main-large">
                Пароль
            </label>

            <input class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_type_input
            dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
            v-bind:class="{ 'dialog__input-text_theme_invalid': v$.password.$invalid && v$.password.$dirty }"
            id="password" type="password" placeholder="Используйте сложный пароль" v-model="password">

            <div v-if="v$.password.required.$invalid && v$.password.$dirty"
            class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                Поле не должно быть пустым
            </div>

            <div v-else-if="v$.password.minLength.$invalid && v$.password.$dirty"
            class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                Поле должно содержать не менее {{ v$.password.minLength.$params.min }} символов
            </div>
        </div>

        <div class="dialog__field">
            <label class="i-font i-font_family_montserrat i-font_antialiased i-font_type_main-large">
                Пароль (повторно)
            </label>

            <input class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_type_input
            dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
            v-bind:class="{ 'dialog__input-text_theme_invalid': 
            ((v$.repPassword.$invalid) || (password !== repPassword)) && v$.repPassword.$dirty }"            
            id="repPassword" type="password" placeholder="Используйте сложный пароль" v-model="repPassword">

            <div v-if="v$.repPassword.required.$invalid && v$.repPassword.$dirty"
            class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                Поле не должно быть пустым
            </div>

            <div v-else-if="v$.repPassword.minLength.$invalid && v$.repPassword.$dirty"
            class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                Поле должно содержать не менее {{ v$.repPassword.minLength.$params.min }} символов
            </div>

            <div v-else-if="password !== repPassword"
            class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                Пароли не совпадают!
            </div>
        </div>

        <button class="i-font i-font_family_montserrat i-font_antialiased i-font_type_main-large
        dialog__single-button single-button single-button_theme_common
        element-click element-click_highlight_text"
        type="submit" value="Submit">
            Зарегистрировать
        </button>


    </form>


</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { registerUser } from '../../scripts/CatalogApi'

export default {

    name: "RegForm",

    props: {

        show: {
            type: Boolean,
            default: true
        }
    },

    setup() {
        return {
            v$: useVuelidate()
        }
    },

    data() {
        return {
            cluster: null,
            login: null,
            password: null,
            repPassword: null,
            isUserErr: false
        }
    },

    validations() {
        return {
            cluster: { required, $autoDirty: true },
            login: { required, email, $autoDirty: true },
            password: { required, minLength: minLength(8), $autoDirty: true },
            repPassword: { required, minLength: minLength(8), $autoDirty: true },
        }

    },


    methods: {
        changeUserErr() {
            this.isUserErr = false;
        },
        hideDialog() {
            this.emitter.emit("DisplayMessage", { message: "На электронную почту " + this.login + " была отправлена ссылка для активации пароля", displaytime: 3000 })
            this.$emit('ok')
        },
        sendRequest() {
            this.v$.login.$dirty = true;
            this.v$.cluster.$dirty = true;
            this.v$.password.$dirty = true;
            this.v$.repPassword.$dirty = true;

            if (!this.v$.$invalid && this.password === this.repPassword) {
                let user = {

                    cluster: this.cluster,
                    username: this.login,
                    password: this.password,
                    is_admin: true,
                    is_operator: false
                }

                registerUser(this.$host, user)
                    .then((response) => {
                        //    if (JSON.stringify(user)===JSON.stringify(response.data)) {
                        //                 this.cluster = null
                        //                 //this.login = null
                        //                 this.password = null
                        //                 this.repPassword = null
                        //                 this.hideDialog()
                        //     }
                        //     this.isUserErr = true
                        if (response.status == 200) this.isUserErr = true;
                    }).catch((error) => {
                        console.log(error);
                    })

            }
        },

    },



}
</script>

<style scoped>
</style>