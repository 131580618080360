import * as CatalogApi from "./CatalogApi"

export function errorHandling (error, component) {
    console.log(error);
    console.log(error?.request.response);
    if(error.response) {
        if (error.response.status == 401) actionsOnResponse401(component);
    }   
}

export function actionsOnResponse401(component) {
    if(!component.isUnmounted) {
        CatalogApi.logoutUser(component.$host, component.$store.getters.USER.token)
        .finally(()=>{
            component.$store.commit('SET_USER',{
                'login': null, 
                'isLogined': false, 
                'token': null, 
                'role': null 
            });
        })    
    }    
} 