<template>
    <div class="dialog-layer dialog-layer_color_gray">

        <div class="dialog dialog_color_common dialog_size_medium
        dialog_zindex_four">

            <div>
                <ul class="header-tabs"
                v-if="(activePage == 'login' || activePage == 'registration')">

                    <li class="header-tabs__tab-half
                    header-tabs__tab-half_theme_common
                    element-click element-click_highlight_text
                    i-font i-font_family_montserrat
                    i-font_antialiased i-font_align_center
                    i-font_type_main-large"

                    :class="{ 'header-tabs__tab-half_theme_active': activePage == 'login'}"

                    @click="activePage = 'login'">
                        Вход
                    </li>
                     
                    <li class="header-tabs__tab-half
                    header-tabs__tab-half_theme_common
                    element-click element-click_highlight_text
                    i-font i-font_family_montserrat
                    i-font_antialiased i-font_align_center
                    i-font_type_main-large"

                    :class="{ 'header-tabs__tab-half_theme_active': activePage == 'registration'}"

                    @click="activePage = 'registration'">
                        Регистрация
                    </li>
                                   
                </ul>

                <ul class="header-tabs" v-if="(activePage == 'resetpass')">
                    <li class="header-tabs__tab-half
                    header-tabs__tab-half_theme_common
                    element-click element-click_highlight_text
                    i-font i-font_family_montserrat
                    i-font_antialiased i-font_align_center
                    i-font_type_main-large"

                    :class="{ 'header-tabs__tab-half_theme_active': activePage == 'login'}"

                    @click="activePage = 'login'">
                        Вход
                    </li>
                     
                    <li class="header-tabs__tab-half
                    header-tabs__tab-half_theme_common
                    element-click element-click_highlight_text
                    i-font i-font_family_montserrat
                    i-font_antialiased i-font_align_center
                    i-font_type_main-large"

                    :class="{ 'header-tabs__tab-half_theme_active': activePage == 'resetpass'}"

                    @click="activePage = 'resetpass'">
                        Сброс
                    </li>
                </ul>
            </div>

            <div class="dialog__inner">
                <RegForm @ok="activePage = 'login'" v-if="activePage == 'registration'"></RegForm>
                <LoginForm @resetpass="Resetpass" v-if="activePage == 'login'"></LoginForm>
                <ResetPassForm @ok="activePage = 'login'" v-if="activePage == 'resetpass'"></ResetPassForm>
            </div>
        </div>
    </div>
</template>


<script>

import RegForm from "../components/RegLogin/RegForm";
import LoginForm from "../components/RegLogin/LoginForm";
import ResetPassForm from "../components/RegLogin/ResetPassForm";

export default {
    name: "RegLogin",

    components: { RegForm, LoginForm, ResetPassForm },

    data() {
        return {
            activePage: 'login',
            messageString: null
        }
    },

    methods: {
        hideDialog() {
            this.$emit('hide')
        },

        Resetpass(m) {
            this.activePage = 'resetpass'
            this.messageString = m

        }
    },
}

</script>
<style scoped>
.header-tabs {
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.header-tabs__tab-half {
    box-sizing: border-box;
    margin: 0;
    padding: 1em;
    flex-basis: 50%;
    flex-grow: 1; 
}

.header-tabs__tab-half_theme_common {
    background-color: var(--main-black-color);
    color: var(--main-white-color);
}

.header-tabs__tab-half_theme_active {
    background-color: var(--inner-background-color);
    color: var(--main-black-color);
}
</style>