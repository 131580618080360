<template>
    <div class="temp-widget">
        <p class="i-font i-font_family_montserrat i-font_antialiased 
        i-font_type_main-large i-font_align_center temp-widget__label">
            {{ this.TTarget}}
        </p>

        <img :src="this.Image" class="temp-widget__img">

        <p class="i-font i-font_family_montserrat i-font_antialiased 
        i-font_type_main-large i-font_align_center temp-widget__label">
            {{ this.TReal}}
        </p>
    </div>   
</template>

<script>
    export default {
        name: "TempWidget",

        props:{
            ttarget:{
                type: Number,
                default: 0
            },
            treal:{
                type: Number,
                default: 0
            },
            img_type:{
                type: String,
                default: ""
            },
        },
        computed: {

            TTarget() {
                if (this.ttarget) return this.ttarget+'°'
                else return "--"
            },
            TReal() {
                if (this.treal) return this.treal+'°'
                else return "--"
            },
            Image(){
                return require('@/assets/images/svg_images/Bed.svg');
            }
            
        },
    }
</script>

<style scoped>
.temp-widget {
    display: flex;
    flex-direction: column;
}

.temp-widget__label{
    margin: 0.1em;
}

.temp-widget__img {
    width: 14em;
    height: 12em;
    mix-blend-mode: multiply;
}
</style>