<template>
    <div class="node-tree" >

        <div class="node-tree__folder">
            <img v-if="visible" src="@/assets/images/svg_images/TreeOpen.svg"
            class="node-tree__button i-font i-font_family_montserrat
            i-font_antialiased i-font_type_main-large element-click"
            @click="visible=!visible">

            <img v-else src="@/assets/images/svg_images/TreeClose.svg"
            class="node-tree__button i-font i-font_family_montserrat
            i-font_antialiased i-font_type_main-large element-click"
            @click="visible=!visible">

            <p class="i-font i-font_family_montserrat
            i-font_antialiased i-font_type_main-large
            node-tree__cluster-name">
                {{tree.clusterName}}   
            </p>
        </div>

        <div :style="{'margin-left': left}">
            <DeviceInTree v-show="visible" 
            v-for="device in tree.entity" :device="device" @deviceset="onSet" @devicedel="onDel"></DeviceInTree>
            
            <device-tree-view v-show="visible"
            v-for="child in tree.children" 
            :tree="child"
            :left="left"
            @deviceset="onSet"
            @devicedel="onDel"
            ></device-tree-view>  
        </div>       
    </div>
</template>

<script>
import DeviceInTree from "./DeviceInTree.vue"

export default{
    name: "DeviceTreeView",
    props: {
        tree: {
            
        },
        left: {
            
        },
    },
    emits: ['deviceset','devicedel'],
    data(){
        return{
            visible: true,
        }
    },
    methods:{
        onSet(event){
            this.$emit('deviceset', event);
        },
        onDel(event){
            this.$emit('devicedel', event);
        }
    },
    components:{
        DeviceInTree
    }
}
</script>

<style scoped>

</style>