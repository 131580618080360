<template>
    <div class="window-with-tabs window-with-tabs_size_common">
        <ul class="i-font i-font_family_montserrat i-font_antialiased
        i-font_type_main-medium i-font_align_center
        tabs tabs_size_common">

            <li class="tabs__tab tabs__tab_theme_common
            element-click element-click_highlight_text"
                :class="{ 'tabs__tab_theme_active': selectedOptionWindow == 1 }" @click="selectedOptionWindow = 1">
                Журнал событий
            </li>

            <li class="tabs__tab tabs__tab_theme_common
            element-click element-click_highlight_text"
                :class="{ 'tabs__tab_theme_active': selectedOptionWindow == 2 }" @click="selectedOptionWindow = 2">
                Камера
            </li>

        </ul>
        <div class="window-with-tabs__main window-with-tabs__main_size_common">
            <ul v-show="selectedOptionWindow == 1" id="log-list"
            class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_log window-with-tabs__log">
            </ul>

            <img v-show="selectedOptionWindow == 2" src="@/assets/images/svg_images/NoPhoto.svg"
                class="window-with-tabs__camera">
        </div>
    </div>
</template>

<script>
import { postCommand, getCommand, deleteCommand } from '../../scripts/CatalogApi';
import { errorHandling } from '../../scripts/FunctionsForServerResponse';

export default {
    props: {
        deviceId: {
            required: true
        },
        newCommand: {

        }
    },

    data() {
        return {
            selectedOptionWindow: 1,
            isUnmounted: false,
            timerUpdateCommand: null
        }
    },

    watch: {
        deviceId(newValue, oldValue) {
            if (newValue != oldValue) {
                clearTimeout(this.timerUpdateCommand);
                this.clearLogList();
                this.updateCommand(newValue);
            }
        },
        newCommand(newValue) {
            if (newValue) {
                if (!this.isFilledLocalStorage(this.deviceId)) {
                    this.sendCommand(newValue);
                }
            }
        }
    },

    methods: {
        getStatusText(status) {
            let state = Number(status);
            let text = "";
            switch (state) {
                case 1:
                    text = "Сервером принята команда:";
                    break;
                case 2:
                    text = "Принтеру отправлена команда:";
                    break;
                case 3:
                    text = "Принята принтером к исполнению команда:";
                    break;
                case 4:
                    text = "Принтером исполнена команда:";
                    break;
                case 5:
                    text = "Отказ в исполнении команды:";
                    break;
            }
            return text;
        },
        getCommandText(commandName) {
            let text = "";
            switch (commandName) {
                case "POWER_ON":
                    text = "Включить питание";
                    break;
                case "POWER_OFF":
                    text = "Выключить питание";
                    break;
                case "PAUSE":
                    text = "Пауза";
                    break;
                case "STOP":
                    text = "Остановить печать";
                    break;
                case "PRINT_LAST":
                    text = "Печать последнего файла";
                    break;
                case "RESUME":
                    text = "Продолжить печать";
                    break;
            }
            return text;
        },
        addElementToLog(commandName, status, answer) {
            let message = ""

            let nowDate = new Date();
            message += "[" + nowDate.toLocaleTimeString() + "]";

            if (status) {
                message += " " + this.getStatusText(status);
                message += " " + this.getCommandText(commandName);
                if (answer) message += " (" + answer + ")";
            }
            else {
                message += " Отправлена команда: " + this.getCommandText(commandName);
            }

            let newLogItem = document.createElement('li');
            newLogItem.textContent = message;
            newLogItem.classList.add('window-with-tabs__list-element');
            newLogItem.classList.add('overflow-ellipsis');

            let logList = document.getElementById('log-list');
            logList.appendChild(newLogItem);
        },
        getCommandFromLocalStorage(deviceId) {
            return JSON.parse(localStorage.getItem(deviceId));
        },
        clearLogList() {
            let elementList = document.getElementById('log-list');
            elementList.innerHTML = "";
        },
        deleteCommandFromAll(deviceId, commandId) {
            deleteCommand(this.$host, this.$store.getters.USER.token, commandId)
                .catch((error) => {
                    errorHandling(error, this);
                })

            this.deleteCommandFromLocalStorage(deviceId);
        },
        getCommandFromServer(commandName, commandId) {
            getCommand(this.$host, this.$store.getters.USER.token, commandId)
            .then((response) => {
                this.addElementToLog(commandName, response.data.state, response.data.answer);

                if (Number(response.data.state) == 4 || Number(response.data.state) == 5)
                    this.deleteCommandFromAll(this.deviceId, commandId);
                else {
                    let interval = 5000;
                    this.timerUpdateCommand = setTimeout(() =>this.updateCommand(this.deviceId), interval);
                }

            }).catch((error) => {
                errorHandling(error, this);
            })
        },
        updateCommand() {
            if (!this.isUnmounted &&
                this.isFilledLocalStorage(this.deviceId)
            ) {
                let command = this.getCommandFromLocalStorage(this.deviceId);

                if (!command.id) {
                    this.deleteCommandFromLocalStorage(this.deviceId);
                } else {
                    this.getCommandFromServer(command.name, command.id);
                }
            }
        },
        isFilledLocalStorage(deviceId) {
            return localStorage.hasOwnProperty(deviceId);
        },
        deleteCommandFromLocalStorage(deviceId) {
            localStorage.removeItem(deviceId);
        },
        addIdToCommand(deviceId, commandName, commandId) {
            let newCommand = {
                id: commandId,
                name: commandName
            };

            localStorage.setItem(deviceId, JSON.stringify(newCommand));
        },
        addCommandToLocalStorage(deviceId, commandName) {
            let newCommand = {
                id: null,
                name: commandName
            };

            localStorage.setItem(deviceId, JSON.stringify(newCommand));
        },
        createDataForSend(deviceId, command) {
            let data = {};

            data['to_device'] = deviceId;
            data['command'] = command.name;

            if (command.value1) data.value1 = command.value1;

            if (command.value2) data.value2 = command.value2;

            return data;
        },
        sendCommand(command) {
            let data = this.createDataForSend(this.deviceId, command);

            this.addCommandToLocalStorage(this.deviceId, data.command);
            postCommand(this.$host, this.$store.getters.USER.token, data).then((response) => {
                this.addIdToCommand(this.deviceId, data.command, response.data);
                this.addElementToLog(data.command);
                this.updateCommand(this.deviceId);

            }).catch((error) => {
                errorHandling(error, this);
            })
        },
    },

    mounted() {
        this.updateCommand(this.deviceId);
    },

    beforeUnmount() {
        this.isUnmounted = true;
    }
}
</script>

<style scoped>
.window-with-tabs {
    display: flex;
    flex-direction: column;
    border: 0.1em solid var(--main-black-color);
}

.window-with-tabs_size_common {
    width: 53%;
    height: 45%;
}

.tabs {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.tabs_size_common {
    height: 12%;
}

.tabs__tab {
    flex-grow: 1;
    padding: 0.7em;

}

.tabs__tab_theme_common {
    background-color: var(--main-black-color);
    color: var(--main-white-color);
}

.tabs__tab_theme_active {
    background-color: var(--inner-background-color);
    color: var(--main-black-color);
}

.window-with-tabs__main {
    box-sizing: border-box;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.window-with-tabs__main_size_common {
    height: 88%;
}

.window-with-tabs__log {
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.window-with-tabs__camera {
    max-width: 35%;
}

@media (max-width: 480px) {
    .window-with-tabs{
        width: 90%;
    }
}
</style>

<style>
.window-with-tabs__list-element {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0.3em;
}
</style>