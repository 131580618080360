<template>   
    <li class="element-device device-list__element-device
    element-device_theme_common element-device_size_common"
    :class="{ 'element-device_theme_selected': dev.isSelected }">

        <div class="block-color block-color_size_common" :style="colorstyle">
            <!-- <SixSquareBar :height="'70%'" :width="'0.8em'"
                :timeLastState="this.dev.body.state ? this.dev.body.state.TimeFromLastState: 31"
                :colorActiveSquare="'#FFFFFF'">                   
            </SixSquareBar> -->
        </div>

        <div class="block-logo block-logo_size_common">
            <img src="@/assets/images/svg_images/MaestroLogoDark.svg"
            class="block-logo__img block-logo__img_size_common">

            <p class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-small i-font_align_center
            block-logo__caption block-logo__caption_size_common">
                {{ this.model }}
            </p>
        </div>
        
        <div class="device-data">

            <div class="device-data__name-signal">
                <p class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_main-large device-data__name
                device-data__name_size_common overflow-ellipsis"> 
                    {{ dev.body.name }}
                </p>

                <!-- <SignalBar v-if="statetoprint != 'Не в сети'" :width="'4.2em'" :height="'1.7em'"
                :border-color="'var(--main-black-color)'"
                :value="this.dev.body.state ? this.dev.body.state.TimeFromLastState: 31">                
                </SignalBar> -->
            </div>

            <p class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_list-small device-data__status
            device-data__status_size_common overflow-ellipsis">
                {{ this.statetoprint }}
            </p>

            <p class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_list-small device-data__file 
            device-data__file_size_common overflow-ellipsis">
                {{this.dev.body.state ? this.dev.body.state.LastPrintFile :
                this.dev.body.states[0].LastPrintFile}}
            </p>

            <div v-if="progress_visible"
            class="block-progress block-progress_size_common" >           
                <ProgressBarSmall :width="'75%'" :height="'1.5em'"
                :value="this.dev.body.state ? this.dev.body.state.Percentcomplete : 
                this.dev.body.states[0].Percentcomplete">
                </ProgressBarSmall>

                <p class="i-font i-font_family_montserrat
                i-font_antialiased i-font_type_main-small
                block-progress__percent block-progress__percent_size_common">
                    {{ this.dev.body.state ? this.dev.body.state.Percentcomplete : 
                    this.dev.body.states[0].Percentcomplete }}%
                </p>
            </div>
        </div>
    </li>
</template>

<script>
import ProgressBarSmall from "../Widgets/ProgressBarSmall.vue";
// import SixSquareBar from "../SixSquareBar.vue";
// import SignalBar from "../Widgets/SignalBar.vue";

export default {
    name: "DeviceInMainList",
    components: {
        ProgressBarSmall,
        // SignalBar,
        // SixSquareBar
    },
    props: {
        dev: {},
    },
    data() {
        return {
            color: null,
            colorstyle: null,
            progress_visible: false
        }
    },
    computed: {

        model() {
            return this.dev.body.model.toUpperCase();
        },

        statetoprint() {
            var state = this.dev.body.state ? this.dev.body.state.State.toUpperCase() : 
            this.dev.body.states[0].State.toUpperCase();

            var color;
            var statetoprint;

            let upperState = this.dev.body.state ? this.dev.body.state.UpperState :
            null;

            if (upperState == "OFFLINE") {
                    color= "#44545F";
                    statetoprint="Не в сети";
                    this.progress_visible = false;
            } else {
                switch (state) {
                    case 'OFF':
                        color = "#174766";
                        statetoprint = "Выключен";
                        this.progress_visible = false;
                        break;

                    case 'POWERED':
                        color = "#005691";
                        statetoprint = "Тестирование";
                        this.progress_visible = false;
                        break;

                    case 'IDLE':
                        color = "#60B6F1";
                        statetoprint = "Готов";
                        this.progress_visible = false;
                        break;

                    case 'PRINTING':
                        color = "#74ABCF";
                        statetoprint = "Печать:";
                        this.progress_visible = true;
                        break;

                    case 'PAUSED':
                        color = "Orange";
                        statetoprint = "Пауза:";
                        this.progress_visible = true;
                        break;

                    case 'RESUMING':
                        color = "Orange";
                        statetoprint = "Возобновление:";
                        this.progress_visible = true;
                        break;

                    case 'CANCELED':
                        color = "#60B6F1";
                        statetoprint = "Отмена:";
                        this.progress_visible = true;
                        break;

                    case 'FINISHED':
                        color = "#60B6F1";
                        statetoprint = "Завершено:";
                        this.progress_visible = true;
                        break;

                    case 'ERROR':
                        color = "#980D0D";
                        statetoprint = "Ошибка";
                        this.progress_visible = false;
                        break;
                }
            }
            this.colorstyle = `background-color: ${color}`; 
            return statetoprint;
        },
    }

}
</script>

<style scoped>
.element-device {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.element-device_size_common {
    width: 100%;
    height: 12%;
}

.element-device_theme_common {
    background-color: var(--device-list-common-color);   
}

.device-list__element-device {
    margin-bottom: 0.5em;
}

.element-device_theme_selected {
    background-color: var(--device-list-selected-color);
}

.block-color {
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-color_size_common {
    width: 5%;
}

.block-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em 0.5em 0.5em 0.5em ;
}

.block-logo_size_common {
    width: 20%;
}

.block-logo__img {
    mix-blend-mode: multiply;
}

.block-logo__img_size_common {
    height: 70%;
    width: 65%;
}

.block-logo__caption {
    margin-bottom: 0em;
    margin-top: 0em;
}

.block-logo__caption_size_common {
    width: 6em;
}

.device-data {
    padding-top: 0.7em;
    width: 65%;
}

.device-data__name-signal {
    display: flex;
    width: 97%;
    align-items: center;
}

.device-data__name {
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0.7em;  
}

.device-data__name_size_common {
    height: 1.1em;
    max-width: 71%;
}

.device-data__status {
    margin-top: 0;
    margin-bottom: 0;
}

.device-data__status_size_common {
    width: 90%;
    height: 1.2em;
}

.device-data__file {
    margin-top: 0;
    margin-bottom: 0.5em;
}

.device-data__file_size_common {
    width: 90%;
    height: 1.3em;
}
.block-progress {
    display: flex;
    align-items: center;
}

.block-progress_size_common {
    width: 90%;
    height: 1.2em;
}

.block-progress__percent {
    margin: 0;
    margin-left: 0.3em;
}

.block-progress__percent_size_common {
    width: 25%;
}
@media (max-width: 480px) {
    .block-logo {
        display: none;
    }

    .device-data {
        width: 90%;
    }

    .element-device {
        height: 8%;
    }
}
</style>