<template>
    <form  @submit.prevent ="sendRequest">

            <div class="dialog__field">
                <label class="i-font i-font_family_montserrat i-font_antialiased i-font_type_main-large">
                    Логин
                </label>

                <input  class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_main-large i-font_type_input
                dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                v-bind:class="{'dialog__input-text_theme_invalid': (v$.login.$invalid && v$.login.$dirty)  }" 
                id="login"  placeholder="Email-адрес" v-model.trim="login">

                <div v-if="v$.login.required.$invalid && v$.login.$dirty"
                class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                    Поле не должно быть пустым
                </div>

                <div v-else-if="v$.login.email.$invalid && v$.login.$dirty"
                class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                    Поле должно содержать e-mail адрес
                </div>
            </div>

            <div class="dialog__field">
                <label class="i-font i-font_family_montserrat i-font_antialiased i-font_type_main-large">
                    Пароль
                </label>
                    
                <input class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_main-large i-font_type_input
                dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                v-bind:class="{'dialog__input-text_theme_invalid': v$.password.$invalid && v$.password.$dirty}"
                id="password" type="password" placeholder="Используйте сложный пароль"   v-model="password" >

                <div v-if="v$.password.required.$invalid && v$.password.$dirty"
                class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                    Поле не должно быть пустым
                </div>

                <div v-else-if="v$.password.minLength.$invalid && v$.password.$dirty"
                class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                    Поле должно содержать не менее {{ v$.password.minLength.$params.min }} символов
                </div>

                <div v-if="login_error"
                class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                    Пара Логин/Пароль неверная!
                </div>
            </div>



            <p class="i-font i-font_family_montserrat i-font_antialiased
            i-font_align_center i-font_type_main-small
            dialog__link element-click element-click_highlight_text"
            v-on:click="resetPass">
                Забыли пароль?
            </p>


            
            <button  class="i-font i-font_family_montserrat i-font_antialiased i-font_type_main-large
            dialog__single-button single-button single-button_theme_common
            element-click element-click_highlight_text"
            type="submit" value="Submit" >
                Войти
            </button>
            


    </form>
</template>

<script>
    import useVuelidate from '@vuelidate/core'
    import { required, email, minLength } from '@vuelidate/validators'
    import { loginUser, getAllUsers } from '../../scripts/CatalogApi'
    import { errorHandling } from '../../scripts/FunctionsForServerResponse'

    export default {

        name: "LoginForm",

        props: {

            show: {
                type: Boolean,
                default: true
            }
        },

        setup () {
            return {
                v$: useVuelidate()
            }
        },

        data(){
            return{
                login: null,
                password: null,
                login_error: false,
            }
        },

        validations () {
            return {
                login: {required, email, $autoDirty: true},
                password: {required, minLength: minLength(8), $autoDirty: true},
            }

        },

        methods: {
            hideDialog(){
                this.emitter.emit("DisplayMessage", {message: "Пользователь успешно вошел в систему!", displaytime: 3000 })
            },

            resetPass(){
                this.$emit('resetpass');
            },
            sendRequest(){

                this.v$.login.$dirty=true;
                this.v$.password.$dirty=true;

                if (!this.v$.$invalid) {
                    let user = {
                        username: this.login,
                        password: this.password,
                    }

                    loginUser(this.$host, user)
                    .then((response)=>{

                        if (response.status===200) {
                            
                            let token = response.data.auth_token;

                            let role = null;
                            
                            this.getUserRole(this.$host, token, user.username).then((value)=>{
                                role = value;

                            }).finally(()=>{
                                this.$store.commit('SET_USER', {
                                    'login': user.username, 
                                    'isLogined': true, 
                                    'token': response.data.auth_token,
                                    'role': role
                                });

                                this.hideDialog();
                            });                     
                        }
                        else{  
                             this.login_error = true;
                        }
                           
                    }).catch((error)=>{
                        this.$store.commit('SET_USER',{
                            'login': null,
                            'isLogined': false,
                            'token': null,
                            'role': null
                        });

                        this.login_error = true;
                    });

                }
            },

            getRoleFromUser(user) {
                let role = null;

                if (user.is_admin == true) {
                    role = 'admin';
                } else if (user.is_operator == true) {
                    role = 'operator';
                }

                return role;
            },
            
            async getUserRole(host, token, userName){
                let currentRole = null;

                await getAllUsers(host, token)
                .then((response)=>{
                    for(let user of response.data) {
                        if (user.username == userName) {
                            currentRole = this.getRoleFromUser(user);
                            return;
                        }
                    }

                }).catch((error)=>{
                    errorHandling(error, this);
                })
                
                return currentRole;
            }

        },



    }
</script>

<style scoped>

</style>