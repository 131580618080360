<template>
    <div class="progress-bar-small" :style="progressBarStyle">
        <div class="progress-bar-small__progress" :style="progressStyle"></div>
    </div>
</template>

<script>
    export default {
        props:{
            value: {
                type: Number,
                default: 0
            },
            width: {
                default: 0
            },
            height: {
                default: 0
            }
        },
        computed: {
            progressBarStyle(){
                let newstyle = {};
                newstyle['width'] = this.width;
                newstyle['height'] = this.height;
                
                if (this.value == 100) newstyle['background-color'] = "#3D3D3D";
                else newstyle['background-color'] = "";

                return newstyle;
            },
            progressStyle(){
                let newstyle = {};
                newstyle['width'] = this.value + '%';
                return newstyle;
            },
        }
    }
</script>

<style scoped>
.progress-bar-small {
    border-color: var(--main-black-color);
    border-width: 0.3em;
    border-style: solid;
    display: flex;
}

.progress-bar-small__progress {
    background-color: var(--main-black-color);
    position: relative;
    bottom: 0.1em;
    right: 0.1em;
    height: 1.7em;
}

</style>