<template>
    <div class="node-tree node-tree_flexed" :style="{'margin-left': left}">

        <div v-if="isFolder" class="node-tree__folder">
            <img v-if="visible" src="@/assets/images/svg_images/TreeOpen.svg"
            class="node-tree__button i-font i-font_family_montserrat
            i-font_antialiased i-font_type_main-large element-click" 
            @click="visible=!visible">

            <img v-else src="@/assets/images/svg_images/TreeClose.svg"
            class="node-tree__button i-font i-font_family_montserrat
            i-font_antialiased i-font_type_main-large element-click" 
            @click="visible=!visible">
        </div>
        
        <div>
            <ClusterInTree :cluster="tree.entity" :isFirst="isFirst"
            @clusterset="onSet" @clusterdel="onDel"></ClusterInTree>

            <cluster-tree-view v-show="visible" 
            v-for="child in tree.children" 
            :tree="child"
            :left="left"
            @clusterset="onSet"
            @clusterdel="onDel"
            ></cluster-tree-view>  
        </div>
    </div>       
</template>

<script>
import ClusterInTree from "./ClusterInTree.vue"

export default{
    name: "ClusterTreeView",
    props: {
        tree: {
            
        },
        left: {
            
        },
        isFirst: {
            default: false
        }
    },
    emits: ['clusterset','clusterdel'],
    data(){
        return{
            visible: true,
        }
    },
    methods:{
        onSet(event){
            this.$emit('clusterset', event);
        },
        onDel(event){
            this.$emit('clusterdel', event);
        }
    },
    computed:{
        isFolder(){
            return this.tree.children?.length >0
        }
    },
    components:{
        ClusterInTree
    }
}
</script>

<style scoped>
</style>