<template>
    <nav class="main-navbar main-navbar_background-color_blue">
        <div class="main-navbar__scalable-content">
            <ul class="main-navbar__menu">

                <li v-if="this.$store.getters.USER.isLogined" class="main-navbar__element" 
                @click="push('/')">
                    <a class="link">
                        <img src="@/assets/images/svg_images/Home.svg"
                        class="main-navbar__img-element">
                    </a>
                </li>

                <li v-if="this.$store.getters.USER.isLogined && this.$store.getters.USER.role == 'admin'"
                class="main-navbar__element" 
                @click="push('/clusters')">
                    <a class="link">
                        <img src="@/assets/images/svg_images/Cluster.svg"
                        class="main-navbar__img-element">
                    </a>

                    <a class="link link_highlight_border">
                        <div class="main-navbar__label-element">
                            <p class="i-font i-font_family_montserrat
                            i-font_antialiased i-font_type_header-medium">Кластеры</p>
                        </div>
                    </a>
                </li>

                <li v-if="this.$store.getters.USER.isLogined && this.$store.getters.USER.role == 'admin'"
                class="main-navbar__element"
                @click="push('/users')">
                    <a class="link">
                        <img src="@/assets/images/svg_images/Users.svg"
                        class="link main-navbar__img-element">
                    </a>
                    
                    <a class="link link_highlight_border">
                        <div class="main-navbar__label-element">
                            <p class="i-font i-font_family_montserrat
                            i-font_antialiased i-font_type_header-medium">Пользователи</p>
                        </div>
                    </a>                  
                </li>

                <li v-if="this.$store.getters.USER.isLogined && this.$store.getters.USER.role == 'admin'"
                class="main-navbar__element"
                @click="push('/devices')">
                    <a class="link">
                        <img src="@/assets/images/svg_images/Printer.svg"
                        class="link main-navbar__img-element">
                    </a>
                    
                    <a class="link link_highlight_border">
                        <div class="main-navbar__label-element">
                            <p class="i-font i-font_family_montserrat
                            i-font_antialiased i-font_type_header-medium">Принтеры</p>
                        </div>
                    </a>
                </li>

                <li v-if="this.$store.getters.USER.isLogined && 
                    (this.$store.getters.USER.role == 'admin' || this.$store.getters.USER.role == 'operator')" 
                    class="main-navbar__element" 
                    @click="push('/files')">

                    <a class="link">
                        <img src="@/assets/images/svg_images/File.svg"
                        class="link main-navbar__img-element">
                    </a>
                    
                    <a class="link link_highlight_border">
                        <div class="main-navbar__label-element">
                            <p class="i-font i-font_family_montserrat
                            i-font_antialiased i-font_type_header-medium">Файлы</p>
                        </div>
                    </a>
                </li>

            </ul>

            <ul class="main-navbar__menu main-navbar__menu_content_end">
                <li v-if="this.$store.getters.USER.isLogined" class="main-navbar__element">
                    <img src="@/assets/images/svg_images/User.svg"
                    class="element-click main-navbar__img-element"
                    @click="this.usermenu_isactive = !this.usermenu_isactive">

                    <div class="element-click element-click_highlight_border"
                    :class="{ 'main-navbar__label-element': !this.usermenu_isactive,
                    'block-drop-menu': this.usermenu_isactive }"
                    @click="this.usermenu_isactive = !this.usermenu_isactive">

                        <p class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_header-medium">
                            {{ this.$store.getters.USER.login }}
                        </p>

                        <DropDownMenu v-if="this.usermenu_isactive" @hide="usermenu_isactive = false">
                        </DropDownMenu>
                    </div>
                </li>
            </ul>

        </div>
    </nav>


</template>

<script>
import DropDownMenu from "./DropDownMenu";
import router from "@/router";

export default {
    name: "MainHeader",

    components: { DropDownMenu },

    data() {
        return {
            usermenu_isactive: false

        }
    },

    methods: {
        ShowRegDialog() {
            this.$emit('openregloadform', true);
        },
        push(link) {
            router.push(link)
        }
    }
}
</script>

<style scoped>
.main-navbar {
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 4.6vh;
}

.main-navbar_background-color_blue {
    background: var(--header-background-color);
}

.main-navbar__scalable-content {
    /*Ширина масштабируется также, как и content-window */
    width: min(86.7vw, 16 * 87.3vh / 8);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-content: stretch;
}

.main-navbar__menu {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    gap: 1em;
}

.main-navbar__menu_content_end {
    justify-content: flex-end;
}

.main-navbar__element {
    display: flex;
}

.main-navbar__img-element {
    box-sizing: border-box;
    padding: 0.4em;
    height: 100%;
    width: 4.6vh;
}

.main-navbar__label-element {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
}

.block-drop-menu {
    z-index: 2;
}

@media (max-width: 480px) {
    .main-navbar__label-element {
        display: none;
    }
}
</style>