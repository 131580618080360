<template>
    <div class="progress-bar-big progress-bar-big_theme_common" :style="progressBarStyle">
        <div class="progress-bar-big__progress" :style="progressStyle"></div>
        <div class="progress-bar-big__labels">
            <div class="block-two-labels block-two-labels_align_start">
                <p class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_caption-small progress-bar-big__label" >
                    В работе:
                </p>
                <p class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_main-large progress-bar-big__label">
                    {{this.timePrinting}}
                </p>
            </div>
            <div>
                <p class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_main-large progress-bar-big__label">
                    {{this.value }}%
                </p>
            </div>
            <div class="block-two-labels block-two-labels_align_end">
                <p class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_caption-small progress-bar-big__label">
                    Осталось:
                </p>
                <p class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_main-large progress-bar-big__label">
                    {{this.timeEnd}}
                </p>
            </div>
            
        </div>      
    </div>
</template>

<script>
    export default {
        props:{
            value: {
                type: Number,
                default: 0
            },
            width: {
                default: 0
            },
            height: {
                default: 0
            },
            timePrinting: {

            },
            timeEnd: {

            }
        },
        computed: {
            progressBarStyle(){
                let newstyle = {};
                newstyle['width'] = this.width;
                newstyle['height'] = this.height;
                return newstyle;
            },
            progressStyle(){
                let newstyle = {};

                let newwidth = this.value
                if (newwidth <= 1) newwidth = 0
                else newwidth -= 1;

                newstyle['width'] = newwidth + '%';
                return newstyle;
            },
        }
    }
</script>

<style scoped>
.progress-bar-big {
    position: relative;
}

.progress-bar-big_theme_common {
    border-color: var(--main-black-color);
    border-width: 0.15em;
    border-style: solid;
}

.progress-bar-big__progress {
    background-color: var(--progress-bar-big-color);
    height: 85%;
    z-index: 1;
    margin-top: 0.4em;
    margin-left: 0.4em;
    margin-right: 0.1em;
    margin-bottom: 0.1em;
}

.progress-bar-big__labels {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.3em;
    padding-left: 0.9em;
    padding-right: 0.9em;
}

.block-two-labels {
    display: flex;
    flex-direction: column;  
    width: 45%;
}

.block-two-labels_align_start {
    align-items: flex-start;
}

.block-two-labels_align_end {
    align-items: flex-end;
}

.progress-bar-big__label {
    margin-top: 0.1em;
    margin-bottom: 0.1em;
    max-width: 100%;
}
</style>