<template>
    <div class="dialog-layer dialog-layer_color_gray">
        <div  class="dialog dialog_color_common dialog_size_medium
        dialog_zindex_four">


            <div class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_align_center dialog__name">
                Удаление кластера
            </div>

            <div class="dialog__inner dialog__inner_bordered">              
                <form   @submit.prevent ="sendRequest">

                    <p  class="i-font i-font_family_montserrat i-font_antialiased
                    i-font_type_main-large dialog__subname">
                        {{name}} ({{definition}})
                    </p>


                    <div class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                        Удаление - необратимая операция!
                    </div>

                    <div class="dialog__buttons-two buttons-two">
                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        type="submit" value="Submit">Удалить</button>

                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text" 
                        @click="this.$emit('hide')">Отмена</button>
                    </div>

                </form>

            </div>
        </div>

    </div>

</template>

<script>
    import { getCluster, deleteCluster } from "../../scripts/CatalogApi";
    import useVuelidate from '@vuelidate/core'  
    import { errorHandling } from "../../scripts/FunctionsForServerResponse";

    export default {
        name: "ClusterDeleteForm",
        components: {

        },
        props: {
            cluster: {}
        },
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data(){
            return{

                id: null,
                owner: null,
                name: null,
                definition: null,

            }
        },
        mounted() {
            getCluster(this.$host, this.$store.getters.USER.token, this.cluster.id).then((response)=>{
                if (response.status===200) {
                    this.name=response.data.name;
                    this.definition=response.data.definition;
                    this.id=response.data.id;
                    this.owner=response.data.owner;
                }  
            }).catch((error)=>{
               errorHandling(error, this);
            });
        },

        validations () {
            return {


            }

        },

        methods: {

            sendRequest(){

                if (!this.v$.$invalid) {
                    deleteCluster(this.$host, this.$store.getters.USER.token, this.id).then((response)=>{
                        if (response.status===200) {
                            this.emitter.emit("DisplayMessage", {message: response.data, displaytime: 3000 })
                            this.$emit('hide')
                        }
                    }).catch((error)=>{
                        this.emitter.emit("DisplayMessage", {message: error.response.status+": " + error.response.data.detail, displaytime: 3000 })
                        this.$emit('hide')
                        errorHandling(error, this);
                    });
                }
            },

        },



    }
</script>

<style scoped>

</style>