export function createFileTreeFromArray(sendFiles, sendClusters)
{ 
    let rootClusterId = getClusterRootId(sendClusters); 
    let filesTree  = createUserTree(rootClusterId, sendFiles, sendClusters);
    return filesTree;
}

export function createDeviceTreeFromArray(sendDevices, sendClusters)
{                       
    let rootClusterId = getClusterRootId(sendClusters);     
    let devicesTree = createDeviceTree(rootClusterId, sendDevices, sendClusters);   
    return devicesTree;
}

export function createUserTreeFromArray(sendUsers, sendClusters)
{
    let users = createUserArray(sendUsers,sendClusters);  
    let rootClusterId = getClusterRootId(sendClusters); 
    let usersTree  = createUserTree(rootClusterId, users, sendClusters);
    return usersTree;
}

export function createClusterTreeFromArray(sendClusters)
{
    let clusters = createEntityArray(sendClusters);
    clusters = addPropertyOwnerName(clusters);  
    let rootClusterId = getClusterRootId(clusters);
    let clustersTree = createEntityTree(rootClusterId, clusters);
    return clustersTree;
}

export function getClustersFromDevices(devices){
    let clusters = [];
    let insertIds = [];
    for(let device of devices)
    {
        if(!insertIds.includes(device.owner.id)){
            clusters.push({id: device.owner.id, owner: device.owner.owner});
            insertIds.push(device.owner.id);
        }       
    }
    return clusters;
}

export function createDeviceTree(rootClusterId, devicesForTree, clusters){
    let tree = {};

    tree = innerCreateDeviceTree(rootClusterId, devicesForTree, clusters);

    function innerCreateDeviceTree(currentRootClusterId, devicesForTree, clusters)
    {
        let children = [];
        let clusterDevices = [];
        let currentTree ={}

        currentTree.clusterName = clusters.find( (element)=>{

            return element.id == currentRootClusterId;

        }).name;

        for(let device of devicesForTree)
        {
            if(device.owner.id == currentRootClusterId) clusterDevices.push(device);
        }
        currentTree.entity = clusterDevices;

        for(let cluster of clusters){
            if(cluster.owner == currentRootClusterId && cluster.owner != cluster.id){
                
                let childTree = innerCreateDeviceTree(cluster.id, devicesForTree, clusters);

                if (childTree.entity.length>0){
                    children.push(childTree);
                } else if (childTree.children){
                    children = children.concat(childTree.children);
                }
            }
        }
        
        if (children.length>0) currentTree.children = children;
    
        return currentTree;  
    }

    return tree;
}

export function createUserTree(rootClusterId, usersForTree, clusters){
    let tree = {};

    tree = innerCreateUserTree(rootClusterId, usersForTree, clusters);

    function innerCreateUserTree(currentRootClusterId, usersForTree, clusters)
    {
        let children = [];
        let clusterUsers = [];
        let currentTree ={}

        currentTree.clusterName = clusters.find( (element)=>{

            return element.id == currentRootClusterId;

        }).name;

        for(let user of usersForTree)
        {
            if(user.owner == currentRootClusterId) clusterUsers.push(user);
        }

        currentTree.entity = clusterUsers;

        for(let cluster of clusters){
            if(cluster.owner == currentRootClusterId && cluster.owner != cluster.id){
                
                let childTree = innerCreateUserTree(cluster.id, usersForTree, clusters);

                if (childTree.entity.length>0){
                    children.push(childTree);
                } else if (childTree.children){
                    children = children.concat(childTree.children);
                }
            }
        }
        
        if (children.length>0) currentTree.children = children;
    
        return currentTree;  
    }

    return tree;
}

export function createEntityTree(rootId, arrayForTree){
    let tree = {};
    let findRootEntity = false;
    let i=0;
    let rootEntity = null;
    while(!findRootEntity){
        if(arrayForTree[i].id == rootId){
            findRootEntity = true;
            rootEntity = arrayForTree[i];
        }
    }
    tree = innerCreateEntityTree(rootEntity, arrayForTree, tree);

    function innerCreateEntityTree(rootEntity, arrayForTree, tree)
    {
        let children = [];
        let currentRootId = rootEntity.id;
        tree.entity = rootEntity;

        for(let item of arrayForTree){
            if (item.owner == currentRootId && item.owner != item.id) children.push(innerCreateEntityTree(item, arrayForTree, {}))
        }

        if (children.length>0) tree.children = children;

        return tree;       
    }

    return tree;
}

export function getClusterRootId(clusters){
    let findRoot = false;
    let rootId = -1;
    let i = 0;

    //Проверка на самый главный корень
    while(!findRoot && i<clusters.length){
        if(!clusters[i].owner) {
             findRoot = true;
             rootId = clusters[i].id;
        }
        i++;
    }

    i=0;

    while(!findRoot && i<clusters.length)
    {   
       let isRoot = true;
       //Корень тот, у кого уникальный owner 
       let findIndex = clusters.findIndex((item)=>item.id == clusters[i].owner);
       isRoot = findIndex < 0 ; 
       if(isRoot) {
            findRoot = true;
            rootId = clusters[i].id;
       }
       i++;
    }

    return rootId;
}

export function addPropertyOwnerName(arrayForChanges)
{
    let tableIdName = {};
    for(let item of arrayForChanges)
    {
        tableIdName[item.id] = item.name;
    }
    
    let nameProperty = 'ownername';
    for(let item of arrayForChanges)
    {
        item[nameProperty] = tableIdName[item.owner] ?? "";
    }

    return arrayForChanges;
}

export function createUserArray(sentArray, clusters)
{
    let tableClusterIdName = {}
    clusters.forEach((cluster)=>{tableClusterIdName[cluster.id]=cluster.name;});

    let userArray=[];
    for(let user of sentArray)
    {
        let role=0;
        if(user.is_operator) role=1;
        else if(user.is_admin) role=2;
        
        userArray.push({
            owner: user.owner,
            ownername: tableClusterIdName[user.owner],
            id: user.id,
            username: user.username,
            first_name: user.first_name,
            is_active: user.is_active,
            role: role
        })
    }
    return userArray;
}

export function createEntityArray(sentArray)
{
    let entityArray = [];
    for(let item of sentArray)
    {
        entityArray.push(item)
    }

    return entityArray;
}