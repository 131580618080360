import { createRouter, createWebHistory } from 'vue-router'
import BasicPage from "../views/BasicPage";
import ClusterPage from "../views/ClusterPage";
import UserPage from "../views/UserPage";
import DevicePage from "../views/DevicePage";
import FilePage from "../views/FilePage.vue";

import RegLogin from "../views/RegLoginForm";
import UserPasswordChange from "../views/UserPasswordChangeForm";

const routes = [
  {
    path: '/',
    name: 'Devices',
    component: BasicPage
  },

  {
    path: '/clusters',
    name: 'ClusterPage',
    component: ClusterPage
  },
  {
    path: '/users',
    name: 'UserPage',
    component: UserPage
  },
  {
    path: '/devices',
    name: 'DevicePage',
    component: DevicePage
  },

  {
    path: '/files',
    name: 'FilePage',
    component: FilePage
  },

  {
    path: '/login',
    name: 'RegLogin',
    component: RegLogin
  },

  {
    path: '/ChangePassword',
    name: 'ChangePassword',
    component: UserPasswordChange
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
