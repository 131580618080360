<template>
    <div class="drop-down-menu" @mouseleave="this.$emit('hide')">

        <a class="link link_highlight_border 
        i-font i-font_family_montserrat
        i-font_antialiased i-font_type_header-medium"
        @click="push('/ChangePassword')">
            Сменить пароль  
        </a>

        <hr>

        <a class="link link_highlight_border
        i-font i-font_family_montserrat
        i-font_antialiased i-font_type_header-medium"
        @click="logout">
            Выйти
        </a>
    </div>
</template>

<script>
import router from "@/router";
import { logoutUser } from "../../scripts/CatalogApi";


export default {
    name: "DropDownMenu",

    methods: {
        logout() {
            logoutUser(this.$host, this.$store.getters.USER.token)
            .then((response) => {               
                if (response.status === 204) {
                    this.$store.commit('SET_USER', 
                    { 'login': null, 'isLogined': false, 'token': null, 'role':null });
                }

            }).catch((error) => {
                this.$store.commit('SET_USER', 
                { 'login': null, 'isLogined': false, 'token': null, 'role': null });
            })
        },

        push(link) {
            router.push(link);
        }

    }
}
</script>

<style scoped>
.drop-down-menu {
    box-sizing: border-box;
    background: var(--header-background-color);
    padding: 1em;
}
</style>