<template>
    <div v-if="show" class="basic-page">

        <div class="block-device-list block-device-list_size_common">

            <div class="block-device-list__empty block-device-list__empty_size_common"></div>

            <div class="block-device-list__list block-device-list__list_size_common
            block-device-list__list_firefox-scrollbar">
                <ul class="device-list block-device-list__device-list">
                    <template v-for="device in devices">
                        <DeviceInMainList :dev="device" @click="select(device)"></DeviceInMainList>
                    </template>
                </ul>
            </div>

        </div>

        <DeviceDetailed :dev="this.selectedDevice" v-if="selectedDevice"> </DeviceDetailed>

    </div>

</template>

 <script>

import DeviceInMainList from "../components/BasicPage/DeviceInMainList";
import DeviceDetailed from "../components/BasicPage/DeviceDetailed";
import { getAllDevices } from "../scripts/CatalogApi";
import { errorHandling } from "../scripts/FunctionsForServerResponse";
import router from "@/router";

export default {
    name: "Devices",
    components: {
        DeviceInMainList,
        DeviceDetailed,

    },


    data() {

        return {
            show: true,
            devices: null,
            selectedDevice: null,
            isInitialize: false,
            timerUpdate: null
        }
    },
    methods: {
        select(device) {
            this.devices.forEach((dev) => {
                dev.isSelected = false
            })
            device.isSelected = true;
            this.selectedDevice = device;
        },
        update() {
            if (this.$store.getters.USER.token) {
                getAllDevices(this.$host, this.$store.getters.USER.token)
                .then((response) => {

                    var devices = [];
                    response.data.forEach((dev) => {
                        if (dev.states?.length > 0 || dev.state) {
                            if (this.isInitialize &&
                                (this.selectedDevice.body.devid == dev.devid)) {
                                devices.push({ body: dev, isSelected: true });
                                this.selectedDevice = devices[devices.length - 1];
                            } else {
                                devices.push({ body: dev, isSelected: false });
                            }
                        }
                    })

                    this.devices = devices;

                    if (!this.isInitialize) {
                        devices[0].isSelected = true;
                        this.selectedDevice = this.devices[0];
                        this.isInitialize = true;
                    }

                    //Обновление с заданным интервалом
                    let interval = 5000;
                    this.timerUpdate = setTimeout(this.update, interval);
                }).catch((error) => {
                    errorHandling(error, this);
                });
            }
            
        },
        push(link) {
            router.push(link)
        }
    },

    mounted() {
        this.update();
    },

    beforeUnmount() {
        clearTimeout(this.timerUpdate);
    }
}
</script>

<style scoped>
.basic-page {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.block-device-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.block-device-list_size_common {
    width: 19.3%;
    height: 100%;
}

.block-device-list__empty_size_common {
    height: 6.3%;
}

.block-device-list__list {
    overflow-y: auto;
    overflow-x: hidden;
}

.block-device-list__list_size_common {
    height: 93.7%;
}

.block-device-list__list_firefox-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-button-color) var(--scrollbar-background-color);
}

.block-device-list__list::-webkit-scrollbar {
    width: 0.9em;
    height: 100%;
    background-color: var(--scrollbar-background-color);
}

.block-device-list__list::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-button-color);
}

.device-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 100%;
}

.block-device-list__device-list {
    margin-right: 0.5em;
}
</style>