<template>
    <div class="dialog-layer dialog-layer_color_gray">
        <div class="dialog dialog_color_common dialog_size_medium
        dialog_zindex_four">

            <div class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_align_center dialog__name">
                Редактирование кластера
            </div>

            <div class="dialog__inner">

                <form   @submit.prevent ="sendRequest">
                   
                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Название
                        </label>

                        <input  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        v-bind:class="{'dialog__input-text_theme_invalid': (v$.name.$invalid && v$.name.$dirty)  }"
                        id="name"  placeholder="Учебный класс" v-model.trim="name">
                        
                        <div v-if="v$.name.required.$invalid && v$.name.$dirty"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле не должно быть пустым
                        </div>
                    </div>

                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Описание
                        </label>

                        <input  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        id="definition"  placeholder="" v-model.trim="definition">
                    </div>

                    <div class="dialog__buttons-two buttons-two">
                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        type="submit" value="Submit">ОК</button>

                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        @click="this.$emit('hide')">Отмена</button>
                    </div>

                </form>

            </div>
        </div>

    </div>

</template>

<script>
    import { getCluster, putCluster } from "../../scripts/CatalogApi.js";
    import useVuelidate from '@vuelidate/core';
    import { required,} from '@vuelidate/validators';
    import { errorHandling } from "../../scripts/FunctionsForServerResponse";


    export default {
        name: "ClusterEditForm",
        components: {

        },
        props: {
            cluster: {}
        },
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data(){
            return{

                id: null,
                owner: null,
                name: null,
                definition: null,
            }
        },
        mounted() {
            getCluster(this.$host, this.$store.getters.USER.token, this.cluster.id).then((response)=>{
                if (response.status===200) {
                    this.name=response.data.name;
                    this.definition=response.data.definition;
                    this.id=response.data.id;
                    this.owner=response.data.owner;
                }
            }).catch((error)=>{
                console.log(error);
            });
        },

        validations () {
            return {

                name: {required, $autoDirty: true},
            }

        },

        methods: {

            sendRequest(){

                this.v$.name.$dirty=true;

                if (!this.v$.$invalid) {
                    let cluster = {
                        name: this.name,
                        definition: this.definition
                    }
                    putCluster(this.$host, this.$store.getters.USER.token, this.id, cluster).then((response)=>{
                        if (response.status===200) {
                            this.emitter.emit("DisplayMessage", {message: response.data, displaytime: 3000 });
                            this.$emit('hide');
                        }
                    }).catch((error)=>{
                        this.emitter.emit("DisplayMessage",
                        {message: error.response.status+": " + error.response.data.detail, displaytime: 3000 });
                        this.$emit('hide');
                        errorHandling(error, this);  
                    });
                }
            },

        },



    }
</script>

<style scoped>

</style>