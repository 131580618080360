<template>
    <div class="sensor-widget">
        <img :src="this.Image" class="sensor-widget__img">

        <p class="i-font i-font_family_montserrat i-font_antialiased 
        i-font_type_main-large i-font_align_center sensor-widget__label">
            {{this.State}}
        </p>
    </div>
</template>

<script>
    export default {
        name: "FilSensorWidget",

        props:{
            isEnable:{
                type: Boolean,
                default: false
            },
            isError:{
                type: Boolean,
                default: false
            },
        },

        computed: {

            State(){
              return this.isEnable? "ON" : "OFF"
            },

            Image(){
                let srcImage = null;
                
                switch (this.isError) {
                    case true:
                        srcImage = require('@/assets/images/svg_images/FilSensorRed.svg');
                        break;

                    case false:
                        srcImage = require('@/assets/images/svg_images/FilSensorBlack.svg');
                        break;

                    default:
                        srcImage = require('@/assets/images/svg_images/FilSensorBlack.svg');
                        break;
                }

                return srcImage;
            }
        },



    }
</script>

<style scoped>
.sensor-widget {
    display: flex;
    flex-direction: column;
}

.sensor-widget__img {
    width: 13.5em;
    height: 13.5em;
    mix-blend-mode: multiply;
    margin-top: 1.5em;
}

.sensor-widget__label {
    margin: 0.1em;
}
</style>