<template>
    <div class="dialog-layer dialog-layer_color_gray">
        <div class="dialog dialog_color_common dialog_size_medium
        dialog_zindex_four">

            <div class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_align_center dialog__name">
                Редактирование пользователя
            </div>

            <div  class="dialog__inner">

                <form   @submit.prevent ="sendRequest">

                    <p class="i-font i-font_family_montserrat i-font_antialiased
                    i-font_type_main-large dialog__subname" >
                        {{ this.username }}
                    </p>

                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Имя
                        </label>

                        <input  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        id="definition"  placeholder="" v-model="this.first_name">
                    </div>

                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Роль
                        </label>

                        <select class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        v-model="this.sel_role">
                            <option v-for="role in roles" v-bind:value="role.value">
                                {{ role.text }}
                            </option>
                        </select>
                    </div>

                    <div class="field-checkbox">                         
                        <label class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large" 
                        for="checkbox" >
                            {{ user.is_active ? 'Активен' : 'Не активен' }}
                        </label>

                        <input class="checkbox-single"
                        type="checkbox" id="checkbox" v-model="this.is_active">
                    </div>

                    <div class="dialog__buttons-two buttons-two">
                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        type="submit" value="Submit">ОК</button>

                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        @click="this.$emit('hide')">Отмена</button>
                    </div>

                </form>

            </div>
        </div>

    </div>

</template>

<script>
    import useVuelidate from '@vuelidate/core'
    import { putUser } from '../../scripts/CatalogApi'
    import { errorHandling } from "../../scripts/FunctionsForServerResponse";

    export default {
        name: "UserEditForm",
        components: {

        },
        props: {
            user: {}
        },
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data(){
            return{
                username: this.user.username,
                first_name: this.user.first_name,
                sel_role: this.user.role,
                is_active: this.user.is_active,

                roles:[
                    {text: 'Наблюдатель', value: 0},
                    {text: 'Оператор', value: 1},
                    {text: 'Администратор', value: 2},
                ],


            }
        },
        mounted() {

        },

        validations () {
            return {


            }

        },

        methods: {

            sendRequest(){



                if (!this.v$.$invalid) {

                    let newUser = {
                        first_name: this.first_name,
                        is_admin: this.sel_role ===2 ? true : false,
                        is_operator: this.sel_role ===1 ? true : false,
                        is_active: this.is_active
                    }
                    putUser(this.$host, this.$store.getters.USER.token, this.user.id, newUser)
                    .then((response)=>{
                        if (response.status===200) {
                            this.emitter.emit("DisplayMessage", {message: response.data, displaytime: 3000 });
                            this.$emit('hide');

                        }
                    }).catch((error)=>{
                        this.emitter.emit("DisplayMessage", 
                        {message: error.response.status+": " + error.response.data, displaytime: 3000 });                  
                        this.$emit('hide');
                        errorHandling(error, this);   
                    });                         
                }
            },

        },

    }
</script>

<style scoped>
.field-checkbox  {
    display: flex;
    justify-content: space-between;
    height: 5em;
}
.checkbox-single {
    box-sizing: border-box;
    height: 100%;
    width: 13%;
    margin: 0;
}
</style>