import axios from "axios";

export async function deleteFile(host, token, fileId){
    let response = await axios(
        {
            method: "delete",
            url: host+"/api/printfiles/"+fileId+"/",
            headers: {
                'Authorization': 'Token '+token,
            },
        })

    return response;
}

export async function putFile(host, token, fileId, file){
    let response = await axios(
        {
            method: "put",
            url: host+"/api/printfiles/"+fileId+"/",
            headers: {
                'Authorization': 'Token '+token,
            },
            data: file
        })

    return response;
}

export async function getFile(host, token, fileId){
    let response = await axios(
        {
            method: "get",
            url: host+"/api/printfiles/"+fileId+"/",
            headers: {
                'Authorization': 'Token '+token,
            },
        })

    return response;
}

export async function postFile(host, token, formData){
    let response = await axios(
        {
        method: "post",
        url: host+"/api/printfiles/",
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token '+token,
        },
        data: formData
    })

    return response;
}

export async function deleteCommand(host, token, commandId){
    let response = await axios({
        method: "delete",
        url: host+"/api/commands/"+commandId,
        headers: { 
            'Authorization': 'Token '+token,
        }
    })
    return response;
}

export async function getCommand(host, token, commandId){
    let response = await axios({
        method: "get",
        url: host+"/api/commands/"+commandId,
        headers: { 
            'Authorization': 'Token '+token,
        }
    })
    return response;
}

export async function postCommand(host, token, command){
    let response = await axios({
        method: "post",
        url: host+"/api/commands/",
        headers: { 
            'Authorization': 'Token '+token,
        },
        data: command
    })
    return response;
}

export async function logoutUser(host, token){
    let response = await axios({
        method: "post",
        url: host+"/auth/token/logout/",
        headers: { 
            'Authorization': 'Token '+token,
        },
        data: token
    })

    return response;
}

export async function resetPassword(host, user){
    let response = await axios({
        method: "post",
        url: host+"/api/users/resetpass/",
        data: user
    })

    return response;
}

export async function registerUser(host, user){
    let response = await axios({
        method: "post",
        url: host+"/api/users/reg/",
        data: user
    })

    return response;
}

export async function loginUser(host, user){
    let response = await axios({
        method: "post",
        url: host+"/auth/token/login/",
        data: user
    })

    return response;
}

export async function changePassword(host, token, password){
    let response = await axios({
        method: "put",
        url: host+"/api/users/changepass",
        headers: { 
            'Authorization': 'Token '+token,
        },
        data: password
    })

    return response;
}

export async function postUser(host, token, user){
    let response = await axios({
        method: "post",
        url: host+"/api/users/",
        headers: { 
            'Authorization': 'Token '+token,
        },
        data: user
    })

    return response;
}

export async function putUser(host, token, userId, user){
    let response = await axios({
        method: "put",
        url: host+"/api/users/"+userId+"/",
        headers: { 
            'Authorization': 'Token '+token,
        },
        data: user
    })
    return response;
}

export async function getUser(host, token, userId){
    let response = await axios({
        method: "get",
        url: host+"/api/users/"+userId+"/",
        headers: { 
            'Authorization': 'Token '+token,
        }
    })

    return response;
}

export async function deleteUser(host, token, userId){
    let response = await axios({
        method: "delete",
        url: host+"/api/users/"+userId+"/",
        headers: { 
            'Authorization': 'Token '+token,
        }
    })

    return response;  
}

export async function postDevice(host, token, device){
    let response = await axios({
        method: "post",
        url: host+"/api/devices/",
        headers: { 
            'Authorization': 'Token '+token,
        },
        data: device
    })

    return response;
}

export async function putDevice(host, token, deviceId, device){
    let response = await axios({
        method: "put",
        url: host+"/api/devices/"+deviceId+"/",
        headers: { 
            'Authorization': 'Token '+token,
        },
        data: device
    })
    return response;
}

export async function getDevice(host, token, deviceId){
    let response = await axios({
        method: "get",
        url: host+"/api/devices/"+deviceId+"/",
        headers: { 
            'Authorization': 'Token '+token,
        }
    })

    return response;
}

export async function deleteDevice(host, token, deviceId){
    let response = await axios({
        method: "delete",
        url: host+"/api/devices/"+deviceId+"/",
        headers: { 
            'Authorization': 'Token '+token,
        }
    })

    return response;  
}

export async function putCluster(host, token, clusterId, cluster){
    let response = await axios({
        method: "put",
        url: host+"/api/clusters/"+clusterId+"/",
        headers: { 
            'Authorization': 'Token '+token,
        },
        data: cluster
    })

    return response;
}

export async function deleteCluster(host, token, clusterId){
    let response = await axios({
        method: "delete",
        url: host+"/api/clusters/"+clusterId+"/",
        headers: { 
            'Authorization': 'Token '+token,
        }
    })

    return response;  
}

export async function getCluster(host, token, clusterId){
    let response = await axios({
        method: "get",
        url: host+"/api/clusters/"+clusterId+"/",
        headers: { 
            'Authorization': 'Token '+token,
        }
    })

    return response;
}

export async function postCluster(host, token, cluster){
    let response = await axios({
        method: "post",
        url: host+"/api/clusters/",
        headers: { 
            'Authorization': 'Token '+token,
        },
        data: cluster
    })

    return response;
}

export async function getAllFiles(host, token){
    let response = await axios(
        {
            method: "get",
            url: host+"/api/printfiles/",
            headers: {
                'Authorization': 'Token '+token,
            },
        })

    return response;
}

export async function getAllDevices(host, token){
    let response = await axios({
        method: "get",
        url: host+"/api/devices/",
        headers: {
            'Authorization': 'Token '+token,
        }
    })

    return response;
}

export async function getAllClusters(host, token){
    let response = await axios({
        method: "get",
        url: host+"/api/clusters/",
        headers: {
            'Authorization': 'Token '+token,
        }
    })

    return response;
}

export async function getAllUsers(host, token){
    let response = await axios({
        method: "get",
        url: host+"/api/users/",
        headers: {
            'Authorization': 'Token '+token,
        }
    })

    return response;
}


