<template>
    <div v-if="show"
    class="message-block message-block_color_common
    message-block_zindex_four">
        <p class="i-font i-font_family_montserrat
        i-font_antialiased i-font_type_main-large">
            {{ messageString }}
        </p>
    </div>
</template>

<script>
export default {
    name: "ModalDisplayMessanger",

    data() {
        return {
            messageString: "",
            show: false

        }
    },

    created() {
        this.emitter.on("DisplayMessage", (e) => {
            this.messageString = e.message;
            this.show = true;

            setTimeout(() => {
                    this.show = false;
                },
                e.displaytime
            );

        });

    },

}
</script>

<style scoped>
.message-block {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
    border: 0.1em solid var(--main-black-color);
    padding: 1.5em;
}

.message-block_color_common {
    background-color: var(--inner-background-color);
}

.message-block_zindex_four {
    z-index: 4;
}
</style>