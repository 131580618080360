<template>
    <div class="dialog-layer dialog-layer_color_gray">
        <div class="dialog dialog_color_common dialog_size_medium
        dialog_zindex_four">

            <div class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_align_center dialog__name">
                Добавление файла
            </div>
            
            <div class="dialog__inner">

                <form  @submit.prevent ="sendRequest">
                   
                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Кластер-владелец
                        </label>

                        <select class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        v-model="selCluster">
                            <option v-for="cluster in clusters" v-bind:value="cluster.id">
                                {{ cluster.name }}
                            </option>
                        </select>

                    </div>

                    <label for="input-upload" class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input i-font_align_center
                        label-upload dialog__label-upload element-click overflow-ellipsis">

                        {{ chosenFile ? chosenFile.name : 'Выберите файл'}}
                    </label>
                    <input id="input-upload" class="input-file" type="file"
                    accept=".gcode, .plgx, .plg" @change="changeFile">

                    <div class="dialog__buttons-two buttons-two">
                        <button class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        type="submit" value="Submit">ОК</button>

                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        @click="this.$emit('hide')">Отмена</button>
                    </div>

                </form>

            </div>
        </div>

    </div>

</template>

<script>
    import { getAllClusters, postFile } from "../../scripts/CatalogApi";
    import { required,} from '@vuelidate/validators';
    import { errorHandling } from "../../scripts/FunctionsForServerResponse";

    export default {
        name: "FileRegisterForm",
        data(){
            return{
                clusters: null,
                selCluster: null,
                chosenFile: null,
            }
        },
        mounted() {

            getAllClusters(this.$host, this.$store.getters.USER.token).then((response)=>{
                if (response.status===200) {
                    this.clusters=response.data;
                    this.selCluster=this.clusters[0].id;
                }
            }).catch((error)=>{
                errorHandling(error, this);
            });
        },
        methods: {
            changeFile(event) {
                this.chosenFile = event.target.files[0];
            },
            sendRequest(){
                if(this.chosenFile) {
                    console.log(this.chosenFile);

                    let formData = new FormData();

                    formData.append('file', this.chosenFile);
                    formData.append('owner', String(this.selCluster));   

                    postFile(this.$host, this.$store.getters.USER.token, formData).then((response)=>{
                        this.$emit('hide');
                    }).catch((error) => {
                        this.emitter.emit("DisplayMessage", 
                        {message: error.response.status+": " + error.response.data.detail, displaytime: 3000 });
                        
                        this.$emit('hide');
                        errorHandling(error, this);
                    });
                    
                }
                
            },

        },

    }
</script>

<style scoped>
.input-file {
    /* box-sizing: border-box;
    color: var(--main-white-color);
    background-color: var(--header-background-color);
    padding: 0.2em;
    width: 100%;
    margin-bottom: 0.5em; */
    display: none;
}

.dialog__label-upload {
    margin-bottom: 1em;
    height: 2.2em;
}

.label-upload {
    box-sizing: border-box;
    display: block;
    background-color: var(--main-white-color);
    width: 100%;
    border: var(--main-background-color) 0.1em solid;
    padding: 0.3em 0.2em 0.3em 0.2em;
}

.label-upload:hover {
    border-width: 0.2em ;
}
</style>