<template>
    <div class="dialog-layer dialog-layer_color_gray">
        <div class="dialog dialog_color_common dialog_size_medium
        dialog_zindex_four">

            <div class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_align_center dialog__name">
                Выберите файл
            </div>
            
            <div class="dialog__inner">

                <p class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_main-large one-line-label">
                    Выбран файл:
                </p>

                <p class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_main-large one-line-label overflow-ellipsis">
                    {{ currentFileName }}
                </p>
                
                <p class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_main-large one-line-label">
                    Файлы:
                </p>

                <ul class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_main-large list-files list-files_size_common">
                    <li v-for="(el, index) in files" @click="currentFileIndex=index"
                    class="list-files__file element-click element-click_highlight_text
                    overflow-ellipsis">
                        {{ el.file_name }}
                    </li>
                </ul>

                <div class="dialog__buttons-two buttons-two">
                    <button class="i-font i-font_family_montserrat i-font_antialiased
                    i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                    element-click element-click_highlight_text"
                    @click="sendFile">ОК</button>

                    <button  class="i-font i-font_family_montserrat i-font_antialiased
                    i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                    element-click element-click_highlight_text"
                    @click="this.$emit('hide')">Отмена</button>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import { getAllFiles } from '../../scripts/CatalogApi';
import { errorHandling } from '../../scripts/FunctionsForServerResponse';


export default {
    props: {
        clusterId: {
            default: null
        },
        lastPrintFile: {
            default: null
        }
    },
    emits: ['hide', 'fileSelected'],
    data(){
        return {
            files: [],
            currentFileIndex: null,
        };
    },
    computed: {
        currentFileName(){
            let fileName = "";

            if (this.currentFileIndex != null) fileName = this.files[this.currentFileIndex].file_name;
            else if (this.lastPrintFile) fileName = this.lastPrintFile;

            return fileName;
        }
    },
    watch: {
        clusterId(newValue, oldValue){
            this.getFiles();
        }
    },
    methods: {
        sendFile(){
            if (this.currentFileName.length >0){
                let file = null;
                
                if (this.currentFileIndex) {
                    file = {};
                    file.fileId = this.files[this.currentFileIndex].id;
                    file.fileName = this.files[this.currentFileIndex].file_name;
                }
                
                this.$emit('fileSelected', file);
            }
            else {
                this.$emit('hide');
            }          
        },
        getFiles(){
            getAllFiles(this.$host, this.$store.getters.USER.token)
            .then((response)=>{
                this.files.length = 0;
                for(let file of response.data){
                    if (file.owner == this.clusterId) {
                        this.files.push(file);
                    }
                }
            }).catch((error)=>{
                errorHandling(error, this); 
            });
        }
    },
    created(){
        this.getFiles();        
    }   
}
</script>

<style scoped>
.list-files {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
}

.list-files_size_common {
    max-height: 15em;
}

.list-files__file {
    margin-bottom: 0.5em;
}

.one-line-label {
    margin-top: 0;
}
</style>