<template>
    <div class="dialog-layer dialog-layer_color_gray">
        <div class="dialog dialog_color_common dialog_size_medium
        dialog_zindex_four">

            <div class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_align_center dialog__name">
                Смена пароля
            </div>

            <div class="dialog__inner">
                
                <form  @submit.prevent ="sendRequest">

                    

                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Старый пароль
                        </label>

                        <input class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        v-bind:class="{'dialog__input-text_theme_invalid': v$.oldpassword.$invalid && v$.oldpassword.$dirty}"
                        id="oldpassword" type="password" placeholder="" v-model="oldpassword" >
                        
                        <div v-if="v$.oldpassword.required.$invalid && v$.oldpassword.$dirty"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле не должно быть пустым
                        </div>
                        
                        <div v-else-if="v$.oldpassword.minLength.$invalid && v$.oldpassword.$dirty"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле должно содержать не менее {{ v$.oldpassword.minLength.$params.min }} символов
                        </div>
                    </div>

                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Новый пароль
                        </label>

                        <input class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        v-bind:class="{'dialog__input-text_theme_invalid': v$.password.$invalid && v$.password.$dirty}"
                        id="password" type="password" placeholder="Используйте сложный пароль" 
                        v-model="password" >

                        <div v-if="v$.password.required.$invalid && v$.password.$dirty"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле не должно быть пустым
                        </div>

                        <div v-else-if="v$.password.minLength.$invalid && v$.password.$dirty"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле должно содержать не менее {{ v$.password.minLength.$params.min }} символов
                        </div>
                    </div>

                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Новый пароль
                        </label>

                        <input class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        v-bind:class="{'dialog__input-text_theme_invalid': 
                        ((v$.repPassword.$invalid) || (password!==repPassword )) && v$.repPassword.$dirty}"
                        id="repPassword" type="password" placeholder="Используйте сложный пароль"
                        v-model="repPassword" >

                        <div v-if="v$.repPassword.required.$invalid && v$.repPassword.$dirty" 
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле не должно быть пустым
                        </div>

                        <div v-else-if="v$.repPassword.minLength.$invalid && v$.repPassword.$dirty"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле должно содержать не менее {{ v$.repPassword.minLength.$params.min }} символов
                        </div>

                        <div v-else-if="password!==repPassword"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Пароли не совпадают!
                        </div>
                    </div>


                    <div class="dialog__buttons-two buttons-two">
                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        type="submit" value="Submit">ОК</button>

                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        @click="push('/')">Отмена</button>
                    </div>
                </form>
            </div>
        </div>

    </div>

</template>

<script>
    import useVuelidate from '@vuelidate/core'
    import { minLength, required,} from '@vuelidate/validators'
    import router from "@/router";
    import { changePassword } from '../scripts/CatalogApi';
    import { errorHandling } from '../scripts/FunctionsForServerResponse';


    export default {
        name: "UserPasswordChange",

        components: {

        },
        props: {

        },
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data(){
            return{



                password: null,
                repPassword: null,
                oldpassword: null



            }
        },
        mounted() {



        },

        validations () {
            return {

                oldpassword: {required, minLength: minLength(8), $autoDirty: true},
                password: {required, minLength: minLength(8), $autoDirty: true},
                repPassword: {required, minLength: minLength(8), $autoDirty: true },

            }

        },

        methods: {

            sendRequest(){



                if (!this.v$.$invalid) {
                    let pass = {
                        password: this.password,
                        oldpassword: this.oldpassword,
                    }
                    changePassword(this.$host, this.$store.getters.USER.token, pass)
                    .then((response)=>{
                        if (response.status===200) {
                            this.emitter.emit("DisplayMessage", {message: response.data, displaytime: 3000 })
                            router.push("/")
                        }
                    }).catch((error)=>{

                        this.emitter.emit("DisplayMessage", 
                        {message: error.response.status+": " + error.response.data, displaytime: 3000 })
                        errorHandling(error, this);
                        //this.$emit('hide')
                    });
   
                }
            },
            push(link){
                router.push(link)
            }

        },



    }
</script>

<style scoped>
  
</style>