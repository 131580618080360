<!-- <template xmlns:animation="http://www.w3.org/1999/xhtml"> -->
<template>
    <div class="cooler-widget">
        <img src="@/assets/images/svg_images/Cooler.svg" class="cooler-widget__img"
        :class="{ rotate: this.speed>0 }" >

        <p class="i-font i-font_family_montserrat i-font_antialiased 
        i-font_type_main-large i-font_align_center
        cooler-widget__label">
            {{ this.speed }}%
        </p>    
    </div>
</template>

<script>
    export default {
        name: "CoolerWidget",

        props:{
            speed:{
                type: Number,
                default: 0
            },
        },
    }
</script>

<style scoped>

.cooler-widget {
    display: flex;
    flex-direction: column;
}

.cooler-widget__label {
    margin: 0.1em;
}

.cooler-widget__img {
    width: 13.8em;
    height: 13.8em;
    mix-blend-mode: multiply;
    margin-top: 1em;
}

@keyframes moving {
    100% {transform: rotate(360deg);}
}
.rotate {
    /* задать анимацию «moving» к текущему элементу */
    animation: moving 3000ms infinite linear;
    /* указать точку координат, вокруг которой будет вращаться объект */
    transform-origin: center;
}

</style>