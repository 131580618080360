import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"

export default createStore({
  state: {
    user: {
      login: null,
      isLogined: false,
      token: null,
      role: null,
    }
  },

  plugins: [createPersistedState()],

  getters: {
      USER: state => {
      return state.user;
        },
  },

  mutations: {
    SET_USER: (state, payload)=> {
      state.user = payload
    },
  },

})
