<template>
    <div class="node-tree">
        
        <div class="node-tree__folder">
            <img v-if="visible" src="@/assets/images/svg_images/TreeOpen.svg"
            class="node-tree__button i-font i-font_family_montserrat
            i-font_antialiased i-font_type_main-large element-click" 
            @click="visible=!visible">

            <img v-else src="@/assets/images/svg_images/TreeClose.svg"
            class="node-tree__button i-font i-font_family_montserrat
            i-font_antialiased i-font_type_main-large element-click"
            @click="visible=!visible">

            <p class="i-font i-font_family_montserrat
            i-font_antialiased i-font_type_main-large
            node-tree__cluster-name">
                {{tree.clusterName}}   
            </p>                 
        </div>

        <div :style="{'margin-left': left}">
            <FileInTree v-show="visible"
            v-for="file in tree.entity" :file="file" @fileset="onSet" @filedel="onDel"></FileInTree>

            <file-tree-view v-show="visible"
            v-for="child in tree.children" 
            :tree="child"
            :left="left"
            @fileset="onSet"
            @filedel="onDel"
            ></file-tree-view>   
        </div>      
    </div>
</template>

<script>
import FileInTree from "./FileInTree.vue"

export default{
    name: "FileTreeView",
    props: {
        tree: {
            
        },
        left: {
            
        },
    },
    emits: ['fileset','filedel'],
    data(){
        return{
            visible: true,
        }
    },
    methods:{
        onSet(event){
            this.$emit('fileset', event);
        },
        onDel(event){
            this.$emit('filedel', event);
        }
    },
    components:{
        FileInTree
    }
}
</script>

<style scoped>

</style>