<template>
    <div class="dialog-layer dialog-layer_color_gray">
        <div class="dialog dialog_color_common dialog_size_medium
        dialog_zindex_four">

            <div class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_align_center dialog__name">
                Регистрация принтера
            </div>

            <div class="dialog__inner">

                <form  v-if="!this.message" @submit.prevent ="sendRequest">
           
                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Название
                        </label>
                        
                        <input  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        v-bind:class="{'dialog__input-text_theme_invalid': (v$.name.$invalid && v$.name.$dirty)  }"
                        id="name"  placeholder="Мой принтер" v-model.trim="name">

                        <div v-if="v$.name.required.$invalid && v$.name.$dirty"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле не должно быть пустым
                        </div>
                    </div>

                    <div class="dialog__field">                        
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Описание
                        </label>

                        <input  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        id="definition"  placeholder="Первый от окна" v-model.trim="definition">
                    </div>

                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Код авторизации
                        </label>

                        <input  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common" 
                        v-bind:class="{dialog_item_invalid:
                        ((v$.code.$invalid && v$.code.$dirty)) || this.errormessage  }"
                        id="code"  placeholder="6 символов" v-model.trim="code">
                        
                        <div v-if="v$.code.required.$invalid && v$.code.$dirty"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле не должно быть пустым
                        </div>

                        <div v-else-if="(v$.code.numeric.$invalid || v$.code.minLength.$invalid ||
                        v$.code.maxLength.$invalid) && v$.code.$dirty"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            Поле должно содержать 6 цифр
                        </div>

                        <div v-if="this. errormessage"
                        class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                            {{ this.errormessage }}
                        </div>
                    </div>

                    <div class="dialog__buttons-two buttons-two">
                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        type="submit" value="Submit">ОК</button>

                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        @click="this.$emit('hide')">Отмена</button>
                    </div>

                </form>

                <div v-if="this.message"
                class="i-font i-font_family_montserrat i-font_antialiased i-font_type_main-large">
                    {{ this.message }}
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import useVuelidate from '@vuelidate/core'
    import { required, numeric, minLength, maxLength } from '@vuelidate/validators'
    import { postDevice } from '../../scripts/CatalogApi'
    import { errorHandling } from "../../scripts/FunctionsForServerResponse";


    export default {
        name: "DeviceRegisterForm",
        components: {

        },
        props: {

        },
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data(){
            return{
                code: null,
                name: null,
                definition: null,
                message: null,
                errormessage: null
            }
        },

        validations () {
            return {
                code: {required, numeric, minLength:minLength(6), maxLength: maxLength(6), $autoDirty: true},
                name: {required, $autoDirty: true},
            }

        },

        methods: {

            sendRequest(){

                this.v$.name.$dirty=true;
                this.v$.code.$dirty=true;

                if (!this.v$.$invalid) {
                    let device = {
                        code: this.code,
                        name: this.name,
                        definition: this.definition
                    }
                    console.log(this.$store.getters.USER.token)
                    postDevice(this.$host, this.$store.getters.USER.token, device).then((response)=>{
                        console.log(response)
                        if (response.status===201) {
                            this.message = response.data;
                            setTimeout(
                                () => {
                                    this.$emit('hide')
                                },
                                2000
                            );
                        }
                        if (response.status===200){
                            this.errormessage=response.data;
                        }
                    }).catch((error)=>{
                        console.log(error)
                        this.errormessage=error.response.status;
                        errorHandling(error, this);
                    });

               }
            },

        },



    }
</script>

<style scoped>

</style>