<template>
  <div class="block-all-space block-all-space_font-size_scalable">
    <MainNavbar></MainNavbar>

    <main class="main-content
    main-content_color_gray">

      <div class="scalable-window-content">
          
          <router-view />
      </div>

      <ModalDisplayMessanger></ModalDisplayMessanger>
    </main> 
  </div>
  
</template>

<script>
import MainNavbar from '@/components/App/MainNavbar.vue';
import router from "@/router";
import ModalDisplayMessanger from "@/components/App/ModalDisplayMessanger";


export default {
  components: {
    ModalDisplayMessanger,
    MainNavbar,
  },

  data() {
    return {
      reglogvisible: false
    }
  },
  
  mounted() {
    if (this.IsLogin) router.push('/')
    else router.push('/login')
  },

  computed: {
    IsLogin() {
      return this.$store.getters.USER.isLogined
    }
  },

  watch: {
    IsLogin() {
      if (this.IsLogin) { router.push('/') }
      else router.push('/login')
    }
  },

}
</script>

<style>
/*Описание глобальных стилей*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

:root {
  /* Переменные связанные с цветами*/
  --main-black-color: #3D3D3D;
  --main-white-color: #FFFFFF;
  --main-background-color: #7B7B7B;
  --main-background-semitrans-color: rgb(123, 123, 123, 0.6);
  --header-background-color: #174766;
  --inner-background-color: #EAF7FF;
  --device-list-common-color: #ACC9DB;
  --device-list-selected-color: #EAF7FF;
  --progress-bar-big-color: #95BDD6;
  --scrollbar-background-color: #8d8d8d;
  --scrollbar-button-color: #acacac;
  --form-common-warning-color: red;
  --link-line-bottom-color: #FFFFFF;
  --element-click-line-bottom-color: #FFFFFF;
}

/*Именование сущностей в методологии БЭМ: 
<блок>__<элемент блока>_<модификатор>_<значение модификатора>*/

/*Стили для текста*/
.i-font_family_montserrat {
  font-family: Montserrat;
}

.i-font_antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-font_type_header-medium { 
  font-size: 1.5em;
  font-weight: bolder;
  color: var(--main-white-color);
}

.i-font_type_header-large {
  font-size: 2em;
  font-weight: bold;
  color: var(--main-white-color);
}

.i-font_type_main-large {
  font-size: 2em;
  font-weight: bold;
  color: var(--main-black-color);
}

.i-font_type_main-small {
  font-weight: bold;
  color: var(--main-black-color);
  font-size: 1.2em;
}

.i-font_type_main-medium {
  font-size: 1.7em;
  font-weight: bold;
  color: var(--main-black-color);
}

.i-font_type_list-small {
  font-weight: 500;
  color: var(--main-black-color);
  font-size: 1.3em;
}

.i-font_type_caption-small {
  font-weight: normal;
  font-size: 1.5em;
  color: var(--main-black-color);
}

.i-font_type_input {
  font-size: 2em;
  font-weight: normal;
  color: var(--main-black-color);
}

.i-font_type_warning {
  color: var(--form-common-warning-color);
  font-weight: normal;
  font-size: 1.2em;
}

.i-font_type_log {
  font-weight: bold;
  color: var(--main-black-color);
  font-size: 1.4em;
}

.i-font_align_center {
  text-align: center;
}

.i-font_align_left {
  text-align: left;
}

.i-font_weight_normal {
  font-weight: normal;
}

/*Стили для  компонентов "<имя сущности>Page.vue"*/
.window-entity {
  width: 100%;
  height: 100%;
}

.window-entity_theme_common {
  background-color: var(--inner-background-color);
}

.window-entity__head {
  padding: 0.1em;
  display: flex;
  justify-content: center;
  position: relative;
}

.window-entity__head_theme_common {
  background-color: var(--main-black-color);
  color: var(--main-white-color);
}

.window-entity__header {
  margin: 0.5em;
}

.window-entity__close-button {
  position: absolute;
  left: 97.6%;
  top: 20%;
  width: 1.5em;
}

.block-tree { 
  white-space: nowrap;
  overflow-x: auto;
}

.block-tree_size_common {
  height: 83%;
  padding-top: 3%;
  padding-left: 5%;
}

.window-entity__footer {
  display: flex;
  justify-content: flex-start; 
}

.window-entity__footer_size_common {
  height: 4%;
  padding-left: 0.5em;
  padding-top: 0.5em;
}
.window-entity__icon {
  height: 100%;
  width: 3.5em;
}

@media (max-width: 480px) {
  .window-entity__close-button {
    left: 95%
  }
}

/*Стили для компонентов "<имя сущности>TreeView.vue"*/
.node-tree_flexed {
  display: flex;
}

.node-tree__folder {
  display: flex;
  margin-bottom: 0.5em;
}

.node-tree__button {
  padding: 0;
  height: 1.3em;
  width: 1.3em;
  margin-right: 0.5em;
}

.node-tree__cluster-name {
  margin: 0;
}

/*Стили для компонентов "<имя сущности>InTree.vue"*/
.node-tree__element-tree {
  margin-bottom: 0.5em;
}

.element-tree {
  display: flex;
  height: 3em;
}

.element-tree__name {
  margin: 0;
  margin-right: 0.5em;
}

.element-tree__button {
  height: 100%;
  margin-right: 0.5em;
  width: 3em;
}

/*Стили для форм*/
.dialog-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-layer_color_gray {
  background-color: var(--main-background-semitrans-color);
}

.dialog_zindex_four {
  z-index: 4;
}

.dialog_color_common {
  background: var(--inner-background-color);
}

.dialog_size_medium {
  width: 39em;
}

.dialog__name {
  background-color: var(--main-black-color);
  color: var(--main-white-color);
  padding: 0.5em;
}

.dialog__subname {
  margin: 0;
  margin-bottom: 1em;
}

.dialog__inner {
  padding: 3em;
}

.dialog__inner_bordered {
  border: var(--main-black-color) 0.2em solid;
}

.dialog__field {
  height: 10em;
}

.dialog__input-text {
  margin-top: 0.5em;
  box-sizing: border-box;
  width: 100%;
  padding: 0.1em 0.2em 0.1em 0.2em;
  outline: none;
  height: 2.2em;
}

.dialog__input-text_color_white {
  background: var(--main-white-color);
}

.dialog__input-text_theme_common {
  border: var(--main-background-color) 0.1em solid;
}

.dialog__input-text_theme_invalid {
  border: var(--form-common-warning-color) 0.1em solid;
}

.dialog__buttons-two {
  margin-top: 1em;
  height: 4em;
}

.buttons-two {
  display: flex;
  justify-content: space-between;
}

.buttons-two__button {
  border: none;
  width: 45%;
  height: 100%;
  outline: none;
}

.buttons-two__button_theme_common {
  background: var(--header-background-color);
  color: var(--main-white-color);
}

.dialog__link {
  margin: 0;
  margin-top: 1em;
}

.dialog__single-button {
  margin-top: 1em; 
}

.single-button {
  border: none;   
  width: 100%;
  height: 2.2em;
  outline: none;
}

.single-button_theme_common {
  background: var(--header-background-color);
  color: var(--main-white-color);
}

@media (max-width: 480px) {
  .dialog-layer {
    font-size: 0.5rem;
  }
}

/*Блок являющийся одной строкой,
при переполнении появляется многоточие*/
.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*Блок являющийся ссылкой*/
.link {
  cursor: pointer;
}

.link_highlight_border:hover {
  border-bottom: 1px solid var(--link-line-bottom-color);
}

.link_highlight_text:hover {
  text-decoration: underline;
}


/*Блок являющийся элементом, на который можно нажать*/
.element-click {
  cursor: pointer;
}

.element-click_highlight_border:hover {
  border-bottom: 1px solid var(--element-click-line-bottom-color);
}

.element-click_highlight_text:hover {
  text-decoration: underline;
}

</style>

<style scoped>
.block-all-space {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.block-all-space_font-size_scalable {
  /*Размер шрифта зависит от ширины viewport,
  ширина viewport не должна превышать значения: 16*(высота viewport)/8,
  сохраняется соотношение сторон 16 к 8*/

  /*0.006 - подобранный коэффициент для размера шрифта*/
  font-size: min(0.006 * 86.7vw, 0.006 * 16 * 87.3vh / 8);
}
.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.main-content_color_gray {
  background-color: var(--main-background-color);
}

.scalable-window-content{
  /*Окно контента масштабируется с сохранением отношения сторон 16 к 8*/
  width: min(86.7vw, 16 * 87.3vh / 8);
  height: min(87.3vh, 8 * 86.7vw / 16);
}

@media (max-width: 480px) {
  .block-all-space {
    font-size: 0.3rem;
  }

  .scalable-window-content{
    /* width: min(86.7vw, 8 * 87.3vh / 16);
    height: min(87.3vh, 16 * 86.7vw / 8); */
    width: 87vw;
    height: 88vh;
  }
}
</style>
