<template>
    <form @submit.prevent ="sendRequest">


            <p  class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_align_center dialog__subname">
                Сброс пароля
            </p>

            <div class="dialog__field">
                <label  class="i-font i-font_family_montserrat i-font_antialiased i-font_type_main-large">
                    Введите e-mail
                </label>

                <input  class="i-font i-font_family_montserrat i-font_antialiased
                i-font_type_main-large i-font_type_input
                dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                v-bind:class="{'dialog__input-text_theme_invalid': (v$.login.$invalid && v$.login.$dirty) }" 
                id="login"  placeholder="Email-адрес" v-model.trim="login">

                <div v-if="v$.login.required.$invalid && v$.login.$dirty"
                class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                    Поле не должно быть пустым
                </div>

                <div v-else-if="v$.login.email.$invalid && v$.login.$dirty"
                class="i-font i-font_family_montserrat i-font_antialiased i-font_type_warning">
                    Поле должно содержать e-mail адрес
                </div>
            </div>


            <button  class="i-font i-font_family_montserrat i-font_antialiased i-font_type_main-large
            dialog__single-button single-button single-button_theme_common
            element-click element-click_highlight_text"
            type="submit" value="Submit" >
                Сбросить
            </button>

    </form>
</template>

<script>
    import useVuelidate from '@vuelidate/core'
    import { required, email, minLength } from '@vuelidate/validators'
    import { resetPassword } from '../../scripts/CatalogApi'

    export default {

        name: "ResetPassForm",

        props: {

            show: {
                type: Boolean,
                default: true
            }
        },

        setup () {
            return {
                v$: useVuelidate()
            }
        },

        data(){
            return{
                login: null,
                password: null,
            }
        },

        validations () {
            return {
                login: {required, email, $autoDirty: true},

            }

        },

        methods: {
            hideDialog(){
                this.emitter.emit("DisplayMessage", {message: "На электронную почту "+this.login+" была отправлена инструкция по сбросу пароля", displaytime: 3000 })
                this.$emit('ok')
            },
            sendRequest(){

                this.v$.login.$dirty=true;

                if (!this.v$.$invalid) {
                    let user = {
                        username: this.login,
                        password: '',
                        cluster: ''

                        }
                    resetPassword(this.$host, user)
                    .then((response)=>{
                        console.log(response)

                        if (response.status===200) {

                             this.hideDialog()
                        }
                    }).catch((error)=>{
                        console.log(error);
                    })

                }
            },

        },



    }
</script>

<style scoped>

</style>