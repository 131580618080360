<template>
    <div class="node-tree">
        
        <div class="node-tree__folder">
            <img v-if="visible" src="@/assets/images/svg_images/TreeOpen.svg"
            class="node-tree__button i-font i-font_family_montserrat
            i-font_antialiased i-font_type_main-large element-click" 
            @click="visible=!visible">

            <img v-else src="@/assets/images/svg_images/TreeClose.svg"
            class="node-tree__button i-font i-font_family_montserrat
            i-font_antialiased i-font_type_main-large element-click" 
            @click="visible=!visible">

            <p class="i-font i-font_family_montserrat
            i-font_antialiased i-font_type_main-large
            node-tree__cluster-name">
                {{tree.clusterName}}   
            </p>                 
        </div>

        <div :style="{'margin-left': left}">
            <UserInTree v-show="visible"
            v-for="user in tree.entity" :user="user" @userset="onSet" @userdel="onDel"></UserInTree>

            <user-tree-view v-show="visible"
            v-for="child in tree.children" 
            :tree="child"
            :left="left"
            @userset="onSet"
            @userdel="onDel"
            ></user-tree-view>   
        </div>      
    </div>
</template>

<script>
import UserInTree from "./UserInTree.vue"

export default{
    name: "UserTreeView",
    props: {
        tree: {
            
        },
        left: {
            
        },
    },
    emits: ['userset','userdel'],
    data(){
        return{
            visible: true,
        }
    },
    methods:{
        onSet(event){
            this.$emit('userset', event);
        },
        onDel(event){
            this.$emit('userdel', event);
        }
    },
    components:{
        UserInTree
    }
}
</script>

<style scoped>

</style>