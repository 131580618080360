<template>
    <div class="dialog-layer dialog-layer_color_gray">
        <div  class="dialog dialog_color_common dialog_size_medium
        dialog_zindex_four">

            <div class="i-font i-font_family_montserrat i-font_antialiased
            i-font_type_main-large i-font_align_center dialog__name">
                Редактирование принтера
            </div>

            <div class="dialog__inner">

                <form   @submit.prevent ="sendRequest">

                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Название
                        </label>

                        <input  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common"
                        v-bind:class="{'dialog__input-text_theme_invalid': (v$.name.$invalid && v$.name.$dirty)  }"
                        id="name"  placeholder="Учебный класс" v-model.trim="name">
                        
                        <div v-if="v$.name.required.$invalid && v$.name.$dirty" class="common-text--warning">
                            Поле не должно быть пустым
                        </div>
                    </div>

                    <div class="dialog__field">
                        <label  class="i-font i-font_family_montserrat
                        i-font_antialiased i-font_type_main-large">
                            Описание
                        </label>

                        <input  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large i-font_type_input
                        dialog__input-text dialog__input-text_color_white dialog__input-text_theme_common" 
                        id="definition"  placeholder="" v-model.trim="definition">
                    </div>



                    <div class="dialog__buttons-two buttons-two">
                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        type="submit" value="Submit">ОК</button>

                        <button  class="i-font i-font_family_montserrat i-font_antialiased
                        i-font_type_main-large buttons-two__button buttons-two__button_theme_common
                        element-click element-click_highlight_text"
                        @click="this.$emit('hide')">Отмена</button>
                    </div>

                </form>

            </div>
        </div>

    </div>

</template>

<script>
    import { putDevice } from '../../scripts/CatalogApi'
    import useVuelidate from '@vuelidate/core'
    import { required,} from '@vuelidate/validators'
    import { errorHandling } from "../../scripts/FunctionsForServerResponse";

    export default {
        name: "DeviceEditForm",
        components: {

        },
        props: {
            device: {}
        },
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data(){
            return{
                id: this.device.devid,
                name: this.device.name,
                definition: this.device.definition,
            }
        },
        mounted() {
            
        },

        validations () {
            return {

                name: {required, $autoDirty: true},
            }

        },

        methods: {

            sendRequest(){

                this.v$.name.$dirty=true;

                if (!this.v$.$invalid) {
                    let newDevice = {
                        name: this.name,
                        definition: this.definition
                    }
                    putDevice(this.$host, this.$store.getters.USER.token, this.id, newDevice).then((response)=>{
                        if (response.status===200) {
                            this.emitter.emit("DisplayMessage", {message: response.data, displaytime: 3000 });
                            this.$emit('hide');

                        } 
                    }).catch((error)=>{
                        this.emitter.emit("DisplayMessage",
                        {message: error.response.status+": " + error.response.data.detail, displaytime: 3000 });
                        this.$emit('hide');
                        errorHandling(error, this); 
                    });    
                }
            },

        },



    }
</script>

<style scoped>
 
</style>